<template>
  <BaseDialog :dialogVisible.sync="dialog" width="500px" :title="title">
    <template>
      <BaseForm ref="form" label-width="100px" :cols="formField" :form="form" :formVisible="dialog">
        <template #parentNameSlot>
          <el-tag type="warning"> {{ data.name }} </el-tag>
        </template>
      </BaseForm>
    </template>
    <template #footer>
      <el-button :loading="loading" type="primary" @click="doSubmit"> 确认 </el-button>
      <LoadingBtn @click="cancel"> 取消 </LoadingBtn>
    </template>
  </BaseDialog>
</template>
<script>
import { padStart } from 'lodash'
import { validData } from '@/components/avue/utils/util'
import { childImageTypeFiled as formField } from '../../field'
import { commonFromMixin } from '@/mixins'
import { add } from '@/api/imageTypeApi'

export default {
  mixins: [commonFromMixin],
  props: {
    data: Object
  },
  data() {
    return {
      loading: false,
      formField,
      form: {
        name: ''
      }
    }
  },

  methods: {
    async doSubmit() {
      const valid = await this.validate()
      if (!valid) return
      this.doAdd()
    },
    async doAdd() {
      this.loading = true
      try {
        const { code } = await add(Object.assign({}, { parentId: this.data.id, treeCode: validData(this.data.treeCode.map(code => padStart(code, 6, '0')).join(''), padStart('0', 24, '0') ) }, this.form))
        if ($SUC({ code })) {
          this.success()
        }
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    }
  }
}
</script>