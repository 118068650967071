<template>
  <div class="tablePart">
    <el-table
      ref="table"
      uiid="zd-table"
      v-loading="tableLoading"
      :data="finalData"
      border
      :span-method="spanMethod"
      :cell-class-name="tableCellClassName"
      :row-class-name="rowClassName"
      @selection-change="selectionChange"
    >
      <el-table-column type="selection" :selectable="selectable"></el-table-column>
      <el-table-column label="订单信息" prop="orderCode" min-width="200">
        <template slot-scope="{ row }">
          <template v-if="row.$isTotal"> 已选中订单数量: {{ selectionTotal }} </template>
          <template v-else-if="row.isHeader">
            <div class="flex-between">
              <div>
                <span uiid="zd-orderCode">
                  订单号：<hoverCopy :value="row.orderCode" class="text-primary">{{ row.orderCode }}</hoverCopy>
                </span>
                <span class="ml20"
                  >分销商：{{ row.orderInfo.nickName || '暂无' }}
                  <span v-if="row.orderInfo.userStatus === 2" style="color: red">(已注销)</span>
                </span>
                <span class="ml20" v-if="row.orderInfo.childrenUserName"
                  >子账号：{{ row.orderInfo.childrenUserName || '暂无'
                  }}<span v-if="row.orderInfo.userStatus === 2" style="color: red">(已注销)</span></span
                >
                <span class="ml20"
                >店铺：{{ getShopName(row.orderInfo.shopId) }}
                </span>
              </div>
            </div>
          </template>
          <template v-else>
            <productInfo
              :data="row.temuOrderItemList"
              :orderData="row"
              :doLayout="doLayout"
              :sup_this="sup_this"
            ></productInfo>
          </template>
        </template>
      </el-table-column>
      
      <el-table-column label="货款" min-width="200" #default="{ row }">
        <div>
          <p>货款：{{ getPaymentProduct(row) }}</p>
        </div>
      </el-table-column>
      <el-table-column
        v-if="orderStatus === DELIVER"
        label="包裹重量"
        prop="sendOutWeight"
        :formatter="sendOutWeightFormatter"
      ></el-table-column>
      
      <el-table-column label="订单状态" :formatter="getLabelOfOrderStatusForTemu"></el-table-column>
  
      <el-table-column label="时间">
        <template slot-scope="{ row }">
          <el-tooltip class="item" effect="dark" placement="top">
            <template #content>
              <div v-for="time in row.$sortTime" :key="time.prop" class="icon-circle">
                {{ time.label }}：{{ time.value }}
              </div>
            </template>
            <div v-if="row.$lastTime">{{ row.$lastTime.label }}：{{ row.$lastTime.value }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="250">
        <template slot-scope="scoped" v-if="!isDeleted(scoped.row)">
          <changeStateBtn isTemuOrder :data="scoped.row" :sup_this="sup_this" v-if="permission.update"></changeStateBtn>
        </template>
      </el-table-column>

      <template slot="empty">暂无数据</template>
    </el-table>
  </div>
</template>

<script>

import { mapGetters } from "vuex"
import { tableFormDataObj } from './tablePartData'
import changeStateBtn from './changeStateBtn'
import orderDetile from './orderDetile'
import editBtn from './editBtn'
import Edit from './edit'

import productInfo from '../productInfo'
import hoverCopy from '@/views/components/hoverCopy'
import {
  WAIT_RELATION,
  WAIT_GENERATE_WAYBILL,
  WAIT_DISPATCH, // 待排单
  IN_DISPATCH, // 排单中
  WAYBILL_TYPE_HAND,
  getExpressWaybillCode,
  EXPIRED,
  IN_PRODUCTION, // 生产中
  WAYBILL_TYPE_SYS,
  WAYBILL_TYPE_NO,
  IS_WAYBILL_CHANGE_YES, //运单换单
  IS_WAYBILL_CHANGE_BACKEND_YES, //后台换单
  ORDER_TYPE_FBA,
  getLabelOfOrderStatusForTemu,
  ORDER_IS_DELETED_YES,
  DELIVER,
  ORDER_TYPE_SMT
} from '@/utils/constant'
import { getLabel, findByvalue } from '@/components/avue/utils/util'
import { parseTime, accAdd, numberAdd, accMul } from '@/utils'
import { checkPermission } from '@/utils/permission'
import { ORDER_TYPE_DIC, CART_ORDER_DIC } from '@/utils/constant/orderConst'
import TemplateDialog from '@/views/fedex/formwork/module/templateDialog.vue'

import { isPaidOrder, isWaybillIsCreatingOrder } from '@/views/order/utils'


export default {
  components: {
    hoverCopy,
    productInfo,
    changeStateBtn,
    orderDetile,
    editBtn,
    Edit,
    TemplateDialog
  },
  props: {
    orderListData: {
      type: Array,
      required: true,
      default: []
    },

    permission: {
      type: Object
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    sup_this: {
      type: Object,
      required: true
    },
    orderStatus: {
      type: String
    },
    isWaybillCreate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ORDER_TYPE_DIC,
      CART_ORDER_DIC,
      DELIVER,
      ORDER_IS_DELETED_YES,
      ORDER_TYPE_FBA,
      factoryData: [],
      WAYBILL_TYPE_HAND,
      EXPIRED,
      WAYBILL_TYPE_SYS,
      WAYBILL_TYPE_NO,
      IS_WAYBILL_CHANGE_YES, //运单换单
      IS_WAYBILL_CHANGE_BACKEND_YES, //后台换单
      // 英国税号
      britain_duty_paragraph: '',
      // ioss 税号
      ioss_duty_paragraph: '',
      IN_PRODUCTION,
      WAIT_DISPATCH, // 待排单
      IN_DISPATCH, // 排单中
      dialogVisible: false,
      dialogData: null,
      singleData: {},
      fbaOrder: 2,
      ordinaryOrder: 1
    }
  },
  computed: {
    ...mapGetters(['shop']),
    shopDic() {
      let dic = {}
      let data = this.shop.shops
      data.map((item) => {
        dic[item.id] = item.shopName
      })
      return dic
    },

    isFbaOrder() {
      return (row) => {
        return row.orderType === ORDER_TYPE_FBA
      }
    },

    //货款
    getPaymentProduct() {
      return (row) => {
        const temuOrderItemList = $GET(row, 'temuOrderItemList', [])
        return temuOrderItemList.reduce((prev, next) => {
          return accAdd(prev, accMul(next.price || 0, next.productCount || 0))
        }, 0)
      }
    },

    errorInfo() {
      return (row) => {
        return $GET(row, 'externalOrderLogistics.errorInfo', '')
      }
    },

    getExpressWaybillFilePath() {
      return (row) => {
        return $GET(row, 'externalOrderLogistics.expressWaybillFilePath', null)
      }
    },

    isShow() {
      return (data) => {
        return checkPermission(data)
      }
    },
    finalData() {
      const data =
        this.orderListData && this.orderListData.length
          ? [
              {
                $isTotal: true
              }
            ].concat(this.orderListData)
          : null
      return data
    },
    selectionTotal({ factoryData }) {
      return factoryData.length
    },
    canEdit() {
      return (row) => {
        const { orderStatus } = row
        const temArr = [WAIT_RELATION, WAIT_GENERATE_WAYBILL, WAIT_DISPATCH, IN_DISPATCH]
        return true
        return temArr.includes(orderStatus)
      }
    },
    KnifeLayout() {
      return (data) => {
        if (data == '0') {
          return '未全部生成'
        } else {
          return '已全部生成'
        }
      }
    },
    expressCompany() {
      return (data) => {
        switch (data) {
          case 1:
            return '申通'
            break
          case 4:
            return '顺丰'
            break
          case 2:
            return 'fedex'
            break
          case 6:
            return '极兔'
            break
        }
      }
    },
    tableFormData({ orderStatus }) {
      return tableFormDataObj[orderStatus] || tableFormDataObj.default
    }
  },
  methods: {
    getLabel,
    findByvalue,
    parseTime,
    getExpressWaybillCode,
    getLabelOfOrderStatusForTemu,
    // 店铺名称
    getShopName(shopId) {
      return shopId ? $GET(this.shopDic, shopId, '暂无') : '暂无'
    },
    //计算退补运费
    calcExtendFee(data) {
      let diffFreightList = []
      let { waybillApplyRecordDTOList: changeRecordDTOList } = data
      if (isWaybillIsCreatingOrder(data) && changeRecordDTOList) {
        changeRecordDTOList = [...changeRecordDTOList, { realTemplateFreight: data.externalOrderFinance?.finalFreight }]
      }
      if (isPaidOrder(data) && changeRecordDTOList?.length > 1) {
        changeRecordDTOList.slice(1).forEach((item, index) => {
          diffFreightList.push(
            this.$math.subtract(item.realTemplateFreight, changeRecordDTOList[index].realTemplateFreight)
          )
        })
        diffFreightList = diffFreightList.filter((item) => item !== 0)
      }
      return diffFreightList.length ? numberAdd(diffFreightList) : 0
    },

    expressName(scoped) {
      if (scoped.orderType == ORDER_TYPE_SMT) {
        return this.logistics_service_name(scoped?.externalOrderLogistics?.expressThird)
      }
      return scoped.expressShippingMethodCnName || scoped.expressCompanyName || '暂无'
    },
    logistics_service_name(expressThird) {
      try {
        return JSON.parse(expressThird)?.logistics_service_name
      } catch (error) {
        return '暂无'
      }
    },
    showDialog(data) {
      this.singleData = data
      this.$nextTick(function () {
        this.$refs.dialog.to()
      })
    },
    selectionChange(selection) {
      this.factoryData = selection
      this.$emit('takeFactory', selection)
    },
    spanMethod({ row, columnIndex }) {
      if (row.$isTotal) {
        if (columnIndex === 1) {
          return [1, 11]
        } else {
          return [0, 0]
        }
      } else if (row.isHeader) {
        if (columnIndex === 0) {
          return [1, 1]
        } else if (columnIndex === 1) {
          return [1, 11]
        } else {
          return [0, 0]
        }
      } else {
        if (columnIndex === 0) {
          return [0, 0]
        } else if (columnIndex === 1) {
          return [1, 2]
        }
      }
      return [1, 1]
    },
    tableCellClassName(params) {
      let { cellClassName } = this
      if (typeof cellClassName === 'function') cellClassName = cellClassName(params)
      let s
      let { column, rowIndex } = params
      if (column.type === 'selection') {
        s = `uiid-zd-${rowIndex}-selection`
      } else {
        s = `uiid-zd-${rowIndex}-${column.property}`
      }
      return [cellClassName, s].filter(Boolean).join(' ')
    },
    rowClassName({ row }) {
      if (row.$isTotal) {
        return this.selectionTotal ? 'total-row' : 'none'
      }
      if (row.isHeader) return 'hover-row-style is-visible'
    },
    selectable(row) {
      return row.isHeader
      //&& !this.isDeleted(row.orderInfo)
    },
    doLayout() {
      return this.$refs.table.doLayout()
    },
    
    isDeleted(row) {
      return row.isDeleted === ORDER_IS_DELETED_YES
    },
    isDeletedFormatter(row) {
      return this.isDeleted(row) ? '是' : '否'
    },
    sendOutWeightFormatter(row, column, value) {
      return `${value}kg`
    }
  }
}
</script>

<style lang="scss" scoped>
.tablePart {
  margin-top: 20px;
}
.tixing {
  margin-left: 10px;
}
.icon-circle {
  &:nth-last-child(2) {
    &::before {
      background-color: #67c23a;
    }
  }
}
</style>
