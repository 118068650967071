/* eslint-disable */
import { HANDLE_STATUS_OBJ } from '@/utils/constant/paymentConst'
import {
  APPROVE_STATUS_PENDING,
  APPROVE_STATUS_PROCESSED,
  APPROVE_STATUS_REFUSED
} from '@/utils/constant/orderDicConst'

export const WAIT_CONFIRM = '10' // 导入待确认
export const WAIT_RELATION = '11' //生成订单-待关联
export const WAIT_GENERATE_WAYBILL = '20' //待生成运单
export const EXPIRED = '29' //已过期
export const WAIT_PAIT = '30' //待付款
export const WAIT_PAYMENT = '30' // 未支付
export const PAID = '31' // 未支付-待审核
export const SUBACCOUNT_REVIEW = '32' // 子账号审核运费
export const WAIT_DISPATCH = '40' //待排单
export const IN_DISPATCH = '41' //排单中
export const IN_PRODUCTION = '42' //生产中
export const WAIT_UPLOAD_EXPRESS = '50' //待选择物流
export const WAIT_UPLOAD_PACKING = '51' //待上传装箱单
export const UPLOADED_PACKING = '52' //已上传装箱单
export const WAIT_SEND_GOODS = '53' //待发货
export const WAIT_PAID_FREIGHT = '54' // 待付运费
export const TEUN_PRODUCED = '65' //已完成
export const DELIVER = '70' //已发货
export const RETURN_GOODS = '72' //退货中
export const RETURNED = '73' //已退货
export const PENDING = '71' // 待处理
export const AFTER_COMPLETE = '79' // 售后完成
export const CANCEL = '80' //已取消
export const FINISH = '90' //已完成
export const DELETED = '88' // 已删除

export const NO_PAID = 0
export const HAS_PAID = 1

export const ORDER_STATUS_LIST = {
  [WAIT_RELATION]: '待关联',
  [WAIT_GENERATE_WAYBILL]: '待生成运单',
  [EXPIRED]: '已过期',
  [WAIT_PAYMENT]: '待付货款',
  [PAID]: '待审核',
  [WAIT_DISPATCH]: '待排单',
  [IN_DISPATCH]: '排单中',
  [IN_PRODUCTION]: '生产中',
  [WAIT_UPLOAD_EXPRESS]: '待选择物流',
  [WAIT_UPLOAD_PACKING]: '待上传装箱单',
  [UPLOADED_PACKING]: '已上传装箱单',
  [WAIT_SEND_GOODS]: '待发货',
  [DELIVER]: '已发货',
  [RETURN_GOODS]: '退货中',
  [RETURNED]: '已退货',
  [PENDING]: '售后待处理',
  [AFTER_COMPLETE]: '售后已处理',
  [CANCEL]: '已取消',
  [FINISH]: '已完成',
  [WAIT_PAID_FREIGHT]: '待付运费',
  [SUBACCOUNT_REVIEW]: '子账号运费审核'
}
export const ORDER_STATUS_DIC = []
for (const key in ORDER_STATUS_LIST) {
  ORDER_STATUS_DIC.push({
    label: ORDER_STATUS_LIST[key],
    value: Number(key)
  })
}

export const TEMU_ORDER_STATUS_LIST = {
  [WAIT_CONFIRM]: '待确认',
  [WAIT_RELATION]: '需关联',
  [WAIT_DISPATCH]: '待排单',
  [IN_DISPATCH]: '排单中',
  [IN_PRODUCTION]: '生产中',
  [TEUN_PRODUCED]: '已生产',
  [DELIVER]: '全部发货',
  [CANCEL]: '交易关闭'
}
export const TEMU_ORDER_STATUS_DIC = []
for (const key in TEMU_ORDER_STATUS_LIST) {
  TEMU_ORDER_STATUS_DIC.push({
    label: TEMU_ORDER_STATUS_LIST[key],
    value: Number(key)
  })
}

// expressWaybillIsCreated	运货单面单是否已生成   0-未生成  1-已经生成 3-面单已打印 7-运单取消 8-生成中  9-生成异常
export const EXPRESS_WAYBILL_NOT_CREATE = 0
export const WAYBILL_NOT_CREATE = '200' //运货单面单未生成
export const EXPRESS_WAYBILL_CREATED = 1
export const EXPRESS_WAYBILL_PRINTED = 3
export const WAYBILL_CREATED = '201' //运货单面单已生成
export const EXPRESS_WAYBILL_CREATING = 8
export const WAYBILL_CREATING = '208' //运货单面单生成中
export const EXPRESS_WAYBILL_CREATE_ERROR = 9
export const WAYBILL_CREATE_ERROR = '209' //运货单面单生成异常
export const WAIT_GENERATE_WAYBILL_OBJ = {
  [EXPRESS_WAYBILL_NOT_CREATE]: '生产中',
  [EXPRESS_WAYBILL_CREATING]: '运单申请中',
  [EXPRESS_WAYBILL_CREATE_ERROR]: '运单申请异常'
}
export const WAIT_GENERATE_WAYBILL_DIC = [
  {
    label: '生产中',
    value: EXPRESS_WAYBILL_NOT_CREATE
  },
  {
    label: '运单申请中',
    value: EXPRESS_WAYBILL_CREATING
  },
  {
    label: '运单申请成功',
    value: EXPRESS_WAYBILL_CREATED
  },
  {
    label: '运单申请成功',
    value: EXPRESS_WAYBILL_PRINTED
  },
  {
    label: '运单申请失败',
    value: EXPRESS_WAYBILL_CREATE_ERROR
  }
]

// 物流公司
export const BA_FANG = 46
export const LI_SHUN_DA = 47

export const EXPRESS_COMPANY_LIST = {
  [BA_FANG]: '八方',
  [LI_SHUN_DA]: '立顺达'
}

//运单是否换单
export const IS_WAYBILL_CHANGE_NO = 0 //运单未换单
export const IS_WAYBILL_CHANGE_YES = 1 //运单换单
export const IS_WAYBILL_CHANGE_BACKEND_YES = 2 //运单后台换单

// unpaidStatus 未付款的支付状态 0 未发起支付，1 已发起支付
export const UNPAID_STATUS_NO = 0
export const UNPAID_STATUS_WAIT = 1
export const WAIT_PAYMENT_NO = WAIT_PAYMENT + UNPAID_STATUS_NO // 订单状态 orderStatus 为30且未支付状态 unpaidStatus 为0时，此时订单为未支付订单
export const WAIT_PAYMENT_WAIT = WAIT_PAYMENT + UNPAID_STATUS_WAIT // 订单状态 orderStatus 为30且未支付状态 unpaidStatus 为1时，此时订单为待付款订单
export const UNPAID_STATUS_OBJ = {
  [WAIT_PAYMENT_NO]: '未支付',
  [WAIT_PAYMENT_WAIT]: '待付货款'
}

export const PAID_NO = PAID + APPROVE_STATUS_PENDING // 未支付-待审核
export const PAID_WAIT = PAID + APPROVE_STATUS_PROCESSED // 待付款-已审核
export const SUB_ACCOUNT_REVIEW_FAILED = PAID + APPROVE_STATUS_REFUSED // 审核失败订单
export const APPROVE_STATUS_OBJ = {
  [PAID_NO]: '未支付-待审核',
  [PAID_WAIT]: '待付款-已审核',
  [SUB_ACCOUNT_REVIEW_FAILED]: '审核失败'
}

// waybillType  运单类型0为未设置，1为手动，2为系统
export const WAYBILL_TYPE_NO = 0
export const WAYBILL_TYPE_HAND = 1
export const WAYBILL_TYPE_SYS = 2
export const WAYBILL_TYPE_DIC = [
  {
    label: '系统生成',
    value: WAYBILL_TYPE_SYS
  },
  {
    label: '外部导入',
    value: WAYBILL_TYPE_HAND
  }
]

// waybillSource 运单号来源搜索：1手动导入，2系统导入
export const WAYBILL_SOURCE_HAND = 1
export const WAYBILL_SOURCE_SYS = 2
export const WAYBILL_SOURCE_DIC = [
  {
    label: '全部',
    value: 'all'
  },
  {
    label: '系统生成',
    value: WAYBILL_SOURCE_SYS
  },
  {
    label: '外部导入',
    value: WAYBILL_SOURCE_HAND
  }
]

// exportStatus 0未导出 1已导出
export const EXPORT_STATUS_NO = 0
export const EXPORT_STATUS_YES = 1

// cutoffStatus 是否已发到工厂 0默认值 1已截单 2已发送工厂
export const CUTOFF_STATUS_CUTOFF = 1
export const CUTOFF_STATUS_FACTORY = 2

export const WAYBILL_CODE_UPLOADED_YES = 1 // 订单组运单号全部上传
export const WAYBILL_CODE_UPLOADED_NO = 0 // 订单组运单号未全部上传

export const ORDER_UN_EXPORTED = 0
export const ORDER_EXPORTED = 1

export const ATTACHMENT_UPLOAD = 1 // 订单组上传附件
export const ATTACHMENT_UN_UPLOAD = 0 // 订单组未上传附件

// 订单是否已删除：isDeleted 0否 1是
export const ORDER_IS_DELETED_NO = '0'
export const ORDER_IS_DELETED_YES = '1'

// `productType` 1-常规产品  2-组合产品 3-组合产品中的产品；默认1，关联时设置',
export const PRODUCT_TYPE_COMBINATION = 2

export const FILTER_DATA = {}

export const PIC_ENABLE = 0
export const PIC_DISABLE = 1

export const PIC_DISABLE_LIST = {
  [PIC_ENABLE]: '启用',
  [PIC_DISABLE]: '禁用'
}

export const PIC_SHELVES_LIST = {
  [PIC_ENABLE]: '上架',
  [PIC_DISABLE]: '下架'
}

export const MENU_TYPE = 0 //后台端

//首重
export const FRIST_WEIGHT = 1

//同重
export const SAME_WEIGHT = 2

// 表格 - 首重excel的key
export const FRIST_WEIGHT_ORDER_KEYS = {
  twoCharCode: ['国家（二字码，中文） ', '国家'],
  startWeight: ['开始重量(g）（*必填）', '开始重量(g）', '开始重量(g)'],
  endWeight: ['结束重量（g) (*必填）', '结束重量（g)'],
  firstWeight: ['首重/起重（g）'],
  firstFreight: ['首重/起重运费（￥）'],
  continuedWeight: ['续重单位重量（g）（*必填）', '续重单位重量（g）'],
  continuedFreight: ['单价（￥）（*必填）', '单价（￥）']
}

// 表格 - 国家模板excel的key
export const COUNTRY_IMPORT_KEYS = {
  countryCnName: '国家/地区中文名称',
  countryEnName: '国家/地区英文名称',
  twoCharCode: '国家/地区两位代码',
  continentDictCode: '所属洲',
  prepaymentType: '是否需要填写税号',
  currency: '报关金额币种'
}

export let expressTrackMethod = {
  1: 'expressWaybillCode',
  2: 'expressServiceCode'
}
export function getExpressWaybillCode(row) {
  if (row.$expressWaybillCode) return row.$expressWaybillCode
  return (row.$expressWaybillCode = row[expressTrackMethod[row.expressTrackMethod]])
}

export function getLabelOfOrderStatus(data) {
  let { orderStatus } = data
  orderStatus = `${orderStatus}`
  let result = ORDER_STATUS_LIST[orderStatus]
  if (orderStatus === IN_PRODUCTION) {
    result = WAIT_GENERATE_WAYBILL_OBJ[data.expressWaybillIsCreated] || result
  } else if (orderStatus === WAIT_PAYMENT) {
    result = UNPAID_STATUS_OBJ[`${orderStatus}${data.unpaidStatus}`] || result
  } else if (orderStatus === PAID) {
    result = APPROVE_STATUS_OBJ[`${orderStatus}${data.approveStatus}`] || result
  } else if ([PENDING, AFTER_COMPLETE].includes(orderStatus)) {
    result = HANDLE_STATUS_OBJ[data.afterSalesApplyRecordDTO?.handleStatus] || result
  }
  return result
}

export function getLabelOfOrderStatusForTemu(data) {
  let { orderStatus } = data
  orderStatus = `${orderStatus}`
  return $GET(TEMU_ORDER_STATUS_LIST, orderStatus, '')
}

/*
* 导入时间  importTime

 取消时间 cancelTime

 截单时间 cutoffTime

 排单时间 sendTime

 生产时间 eportTime (且exportStatus为1)

 发货时间 deliveryTime

 过期时间 expressExpireTime

 退货申请时间 returnApplyTime

 退货完成时间 returnCompleteTime
*
*
*
* */

//产品集图片集类型
export const TYPE_IS_PROTO = 0
export const TYPE_IS_PICTRUE = 1
