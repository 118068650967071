import { apiFactory } from '@/utils/constant'

const orderApi = {
  orderList: '/temuOrderService/temuOrder/temuOrderList', //列表
  update: '/temuOrderService/temuOrder/updateStatus', //修改状态
  export: {
    url: '/temuOrderService/temuOrder/exportAll',//导出
    responseType: 'blob'
  }
}

for (const key in orderApi) {
  orderApi[key] = apiFactory(orderApi[key])
}

export default orderApi