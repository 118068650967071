<template>
  <section class="app-container app-flex-col-container basePicListPage">
    <slot name="top" :sup_this="sup_this"></slot>
    <EHeader
      :designedStyle="designedStyle"
      v-if="isSearch"
      :sup_this="sup_this"
      :query="query"
      :formField="formField"
      @searchChange="clearAllSelectedData"
    />
    <slot name="batchOperation" :sup_this="sup_this">
      <div style="min-height: 30px"></div>
    </slot>

    <CheckAllData4
      v-if="hasCheckAllData4"
      ref="checkAllData4"
      :isPageChange.sync="isPageChange"
      :tableData="data"
      :page="page"
      :size="size"
      :total="total"
      :getAllDataApi="getAllCheckedSelectedDataApi"
      :selectionData.sync="selectedData"
    />

    <PaginationBar
      v-if="needTopPaginationBar"
      :page="page"
      :size="size"
      :total="total"
      @refreshTableEventFun="refreshTableEventFun"
    />
    <div class="flex-col-content">
      <CommonTable
        ref="table"
        height="100%"
        :tableLoading="tableLoading"
        :cell-style="changeCellStyle"
        :cols="cols"
        :infoData="data"
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <template #originalMergeSlot="{ scoped }">
          <defaultImg :content="scoped.chineseName" :carouselList="originalImageList(scoped)"></defaultImg>
          <!-- <div class="merge-wrapper">
            <el-popover placement="right" trigger="hover" :visible-arrow="false">
              <div slot="reference">
                <el-image
                  fit="contain"
                  :src="scoped.imageList && scoped.imageList[0] && scoped.imageList[0].thumbnailPath"
                  :z-index="9999"
                />
              </div>
              <div style="height: 40px; line-height: 32px; background: #fff">
                {{ scoped.chineseName }}
              </div>
              <el-image
                fit="contain"
                :src="scoped.imageList && scoped.imageList[0] && scoped.imageList[0].thumbnailPath"
                style="width: 450px; height: 450px"
                :z-index="9999"
              >
              </el-image>
            </el-popover>
          </div> -->
        </template>
        <template #tortSlot="{ scoped }">
          {{ getTortStatus(scoped) }}
        </template>
        <template #picMergeSlot="{ scoped }">
          <div class="merge-wrapper">
            <el-popover placement="right" trigger="hover" :visible-arrow="false">
              <div slot="reference">
                <defaultImg :src="aLiCompressPicByUrl(scoped.thumbnailPath, 80)"></defaultImg>
                <!-- <el-image fit="contain" :src="scoped.thumbnailPath" :z-index="9999" /> -->
              </div>
              <div style="height: 40px; line-height: 32px; background: #fff">
                {{ scoped.title }}
              </div>
              <defaultImg :src="aLiCompressPicByUrl(scoped.thumbnailPath, 450)"></defaultImg>
              <!-- <el-image fit="contain" :src="scoped.thumbnailPath" style="width: 450px; height: 450px" :z-index="9999">
              </el-image> -->
            </el-popover>
          </div>
        </template>
        <template #mergeSlot="{ scoped }">
          <div class="merge-wrapper">
            <defaultImg
              :src="aLiCompressPicByUrl(scoped.thumbnailPath || scoped.path, 100)"
              :content="scoped.title"
            ></defaultImg>
            <div class="name">
              {{ scoped.title }}
            </div>
          </div>
        </template>
        <!-- <template #prototypeMergeSlot="{ scoped }">
          {{ scoped.productPrototype.name }}
        </template> -->

        <!-- <template #designedEnNameMergeSlot="{ scoped }">
          {{ handleEnName(scoped) }}
        </template> -->
        <template #styleMergeSlot="{ scoped }">
          <el-tag
            style="margin-right: 5px; margin-bottom: 5px; cursor: pointer"
            type="primary"
            :key="index"
            @click="
              changeData(scoped)
              scoped.pIndex = index
            "
            v-for="(item, index) in handleStyle(scoped)"
          >
            {{ item }}
          </el-tag>
        </template>
        <!-- <template #pictureMergeSlot="{ scoped }">
          <el-tag
            style="margin-right: 5px; margin-bottom: 5px"
            type="primary"
            :key="id"
            v-for="{ sizeName, id } in handleSize(scoped)"
          >
            {{ sizeName }}
          </el-tag>
        </template> -->
        <template #showPictureSlot="{ scoped }">
          <defaultImg lazy scroll-container="elTable" :carouselList="originalPicture(scoped)"></defaultImg>
          <!-- <el-popover placement="right" trigger="hover" :visible-arrow="false">
            <div slot="reference" style="text-align: center">
              <el-carousel
                @change="slideChangeHandler($event, scoped)"
                indicator-position="none"
                arrow="always"
                :autoplay="false"
                :loop="false"
                height="50px"
                style="width: 100px"
              >
                <el-carousel-item v-for="item in originalPicture(scoped)" :key="item.currentImageIndex">
                  <el-image
                    fit="contain"
                    style="width: 46px; height: 46px; border: 1px solid #eeeeee"
                    :src="item.path"
                    :z-index="9999"
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
            <el-image
              fit="contain"
              :src="originalPicture(scoped)[current(scoped)] && originalPicture(scoped)[current(scoped)].path"
              style="width: 460px; height: 460px"
              :z-index="9999"
            >
            </el-image>
          </el-popover> -->
        </template>
        <template #basicMergeSlot="{ scoped }">
          {{ handleState(scoped) }}
        </template>
        <template #timeMergeSlot="{ scoped }">
          <span>{{ scoped[scoped.prop] | formatTime }}</span>
        </template>
        <template #picSizeMergeSlot="{ scoped }">
          <color-text-btn type="default"> {{ scoped.width }} * {{ scoped.height }}</color-text-btn>
        </template>
        <template #designedMergeSlot="{ scoped }">
          <span style="display: inline-block; width: 80px" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave"
            >{{ scoped.customProductCount }}
            <span
              v-if="show && scoped.customProductCount !== 0"
              style="color: #3841db; cursor: pointer; margin-left: 10px"
              @click="onWatch(scoped)"
              >查看</span
            ></span
          >
        </template>
        <template #stateMergeSlot="{ scoped }">
          <span v-if="scoped.disable === 1">禁用</span>
          <span v-else>启用</span>
        </template>
        <template #createdUserSlot="{ scoped }">
          <span v-if="scoped.main_user">{{ `${scoped.main_user}-${scoped.created_user_name}` }}</span>
          <span v-else>{{ scoped.created_user_name || '暂无' }}</span>
        </template>
        <template #createByNameSlot="{ scoped }">
          <span v-if="scoped.mainUser">{{ `${scoped.mainUser}-${scoped.createByName}` }}</span>
          <span v-else>{{ scoped.createByName || '暂无' }}</span>
        </template>
        <template #nameSlot="{ scoped }">
          <span v-if="scoped.mainUser">{{ scoped.mainUser }}</span>
          <span v-else>{{ scoped.createByName || '暂无' }}</span>
        </template>
        <template #disableSlot="{ scoped }">
          <span v-if="scoped.disable === 1">下架</span>
          <span v-else>上架</span>
        </template>

        <template #sizeMergeSlot="{ scoped }">
          <color-text-btn type="default">
            {{ suffix(scoped) }}
          </color-text-btn>
        </template>
        <template #categoryNameSlot="{ scoped }">
          {{ scoped.categoryName || '暂无' }}
        </template>
        <template #createTimeSlot="{ scoped }">
          <span>{{ parseTime(scoped[scoped.prop]) || '暂无' }}</span>
        </template>
        <slot :sup_this="sup_this"></slot>
      </CommonTable>
    </div>
    <designedDialog v-if="open" :visible.sync="open" :product="productData" />
    <orderDialog v-if="display" :visible.sync="display" :order="orderData" />
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
  </section>
</template>

<script>
import EHeader from './module/header'
import orderDialog from '../../designedProductManage/module/orderDialog'
import UploadImg from './module/uploadImg'
import designedDialog from './module/designedDialog'
import defaultImg from '@/views/components/defaultImg'
import CheckAllData4 from '@/views/components/checkAllData4'

import checkAll4Mixin from '@/views/components/checkAllData4/checkAll4Mixin'

import { initDataMixin, delMixin } from '@/mixins'
import { getDetail } from '@/api/product/designed'
import { del as delApi, bulkDel as bulkDelApi } from '@/api/imageApi'
import { getImageList } from '@/views/product/maintain/basicsMange/module/api'
import { getOpenList } from '@/views/product/maintain/basicsMange/module/api'
import { getFileSuffix } from '@/utils'
import { parseTime } from '@/utils'

export default {
  mixins: [
    initDataMixin,
    checkAll4Mixin({
      checkoutAllApi: window.location.href.indexOf('publicGallery') != -1 ? getOpenList : getImageList
    }),
    delMixin
  ],
  components: {
    defaultImg,
    EHeader,
    UploadImg,
    designedDialog,
    orderDialog,
    CheckAllData4
  },
  props: {
    cols: {
      type: Array,
      required: true
    },
    formField: {
      type: Array,
      required: true
    },
    resetUrl: {
      type: String,
      default: '/externaladmin/productService/image/list'
    },
    needTopPaginationBar: {
      type: Boolean,
      default: true
    },
    isSearch: Boolean,
    designedStyle: {
      type: Boolean,
      default: false
    },

    hasCheckAllData4: Boolean
  },
  data() {
    return {
      show: false,
      open: false,
      display: false,
      productData: {},
      orderData: {},
      sup_this: this,
      url: this.resetUrl,
      method: 'post'
    }
  },
  computed: {
    suffix() {
      return (row) => {
        return getFileSuffix(row.path)
      }
    },
    current() {
      return (row) => {
        try {
          return row.currentIndex || 0
        } catch (error) {}
      }
    },
    // 原图
    originalImageList() {
      return (product) => {
        try {
          if (!product) return
          let picList = []
          product.imageList.map((item) => {
            picList.push(item.thumbnailPath)
          })
          picList = picList.map((item, index) => item)
          return picList
        } catch (error) {}
      }
    },
    originalPicture() {
      return (product) => {
        console.log('originalPicture', product.pIndex, product)
        const pIndex = product.pIndex || 0
        const { bottomBoardShowImageList } = product
        try {
          if (!product) return
          let picList = []
          product.customProductList[pIndex].customShowImageList.map((item) => {
            picList.push(item.showImagePath)
          })
          ;(bottomBoardShowImageList || []).map(({ clearPath }) => picList.push(clearPath))
          picList = picList.map((item, index) => item)
          return picList
        } catch (error) {}
      }
    }
  },
  methods: {
    delApi,
    bulkDelApi,
    parseTime,
    changeData(data) {
      console.log(data)
      this.$set(this.data, data.index, data)
    },
    getTortStatus(data) {
      if (data.isTort == 1) return '是'
      return '否'
    },
    handleSelectionChange(val) {
      this.selectedData = val
      this.$emit('selectionChange', val)
    },
    handleSortChange(val) {
      const { order } = val
      this.$emit('handleSortChange', val)
      this.mergeData = {
        orderItems: [
          {
            asc: order === 'ascending' ? true : false,
            column: 'create_time'
          }
        ]
      }
      this.init()
    },
    watch(data) {
      this.display = true
      this.orderData = data
    },
    slideChangeHandler(activeIndex, row) {
      this.$set(row, 'currentIndex', activeIndex)
      console.log(row)
    },
    //修改定制产品数这一列表格的文字颜色
    changeCellStyle(row) {
      //列的label的名称
      if (row.column.label === '定制产品数') {
        return 'color: #3841DB;' //修改的样式
      } else {
        return ''
      }
    },
    // handleCnName(data) {
    //   return data.chinese_name ? data.chinese_name : '暂无'
    // },
    handleEnName(data) {
      return data.englishName ? data.englishName : '暂无'
    },
    handleState(data) {
      let generate = true
      data.customProductList?.map(({ customSpecificProductList }) => {
        customSpecificProductList = customSpecificProductList || []
        return customSpecificProductList.map(({ knifeLayoutList }) => {
          if (!knifeLayoutList) return (generate = false)
          knifeLayoutList.every(({ knifeLayoutImagePath }) => {
            if (!knifeLayoutImagePath) return (generate = false)
          })
        })
      })
      return generate ? '已全部生成' : '未全部生成'
    },
    handleStyle(data) {
      if (!data.customProductList) return
      return data.customProductList
        .map((item) => {
          return item.styleName
        })
        .filter((d) => d)
    },
    handleSize(data) {
      return (
        data.customProductList &&
        data.customProductList[0] &&
        data.customProductList[0].customSpecificProductList &&
        data.customProductList[0].customSpecificProductList
          .map((item) => {
            return item
          })
          .filter(({ sizeName }) => sizeName)
      )
    },
    updateImageList({ id } = {}) {
      if (!id) return
      this.url = `/api/image/image/?id=${id}`
      this.page = 1
      this.init()
    },
    //显示查看定制产品基本信息的对话框
    onWatch(data) {
      this.productData = data
      this.open = true
    },
    //鼠标经过事件
    onMouseEnter() {
      this.show = true
    },
    //鼠标移出事件
    onMouseLeave() {
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.basePicListPage.basePicListPage {
  ::v-deep {
    .el-carousel__arrow.el-carousel__arrow {
      border: none;
      outline: 0;
      padding: 0;
      margin: 0;
      height: 36px;
      width: 36px;
      cursor: pointer;
      transition: 0.3s;
      color: #fff;
      position: absolute;
      top: 50%;
      z-index: 10;
      transform: translateY(-50%);
      text-align: center;
      color: #cdcdcd;
      font-weight: 600;
      background: none;
      left: 0;
      font-size: 20px;
    }
    .el-carousel__arrow--left.el-carousel__arrow--left {
      left: 0;
    }
    .el-carousel__arrow--right.el-carousel__arrow--right {
      left: auto;
      right: 0;
    }
  }
  .el-tag {
    display: inline-block;
    margin-bottom: 5px;
  }
  padding: 20px 15px;
  .flex-col-content {
    padding: 0;
  }
  .merge-wrapper {
    display: flex;
    align-items: center;
    .el-image {
      flex: 0 0 44px;
      width: 44px;
      height: 44px;
      background: $bg-color;
    }
    .name {
      margin-left: 10px;
      flex: 1;
    }
  }
}
</style>
