import { requireFun } from "@/utils"
export const bulkCols = [
  {
    prop: 'merge',
    label: '图片',
    minWidth: '150',
    slotName: 'mergeSlot'
  },
  {
    prop: 'categoryName',
    label: '图片分类'
  }
]


export const formField = [
  {
    label: '分类',
    prop: 'categoryId',
    name: 'categoryId',
    colNum: 16,
    placeholder: '请选择分类',
    slotName: 'categorySlot',
    rule: [
      {
        required: true,
        message: requireFun('分类')
      }
    ]
  }
]

