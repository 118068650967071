<template>
  <section>
    <BasePicListPage
      ref="picRef"
      :cols="cols"
      :formField="formField"
      :resetUrl="resetUrl"
      :isSearch="checkPermission(['externaladmin:picture:publicGallery:publicImageSearch'])"
      :hasCheckAllData4="hasCheckAllData4"
    >
      <template v-slot:batchOperation="{ sup_this }">
        <BatchOperation class="batch-operation-wrapper" style="margin-top: 10px">
          <div class="g-left-operation">
            <UploadImg
              :sup_this="sup_this"
              accept="image/jpeg, image/png, image/jpg"
              v-p="['externaladmin:picture:publicGallery:publicImageUp']"
            />
            <BulkDisable type="1" :sup_this="sup_this" v-p="['externaladmin:picture:publicGallery:batchDisable']">
              批量下架
            </BulkDisable>

<!--            <el-button size="small" :sup_this="sup_this" v-p="['externaladmin:picture:publicGallery:tort']" @click="handleTort">-->
<!--              设置侵权-->
<!--            </el-button>-->

            <!-- 生成图片分组 -->
            <CreateAssignGroup
              v-if="hasCreateAssignGroup"
              type="picture"
              style="margin-left: 3px"
              :hasDoSubmit="hasDoSubmit"
              :isSelectedMode="true"
              :sup_this="sup_this"
              @successHandler="sup_this.clearAllSelectedData"
            />

            <!-- <el-button
              v-if="canAssign"
              type="primary"
              size="mini"
              class="filter-item"
              @click="assignDistributor(sup_this.selectedData)"
              >分配分销商</el-button
            > -->
            <BulkChangeCate :sup_this="sup_this" v-p="['externaladmin:picture:publicGallery:categoryBatchUpdate']">
              批量修改分类
            </BulkChangeCate>
          </div>
        </BatchOperation>
      </template>
      <template v-slot="{ sup_this }">
        <el-table-column fixed="right" align="center" label="操作" width="280px">
          <template slot-scope="{ row }">
            <ChangeCate
              :data="row"
              :sup_this="sup_this"
              :isPublic="true"
              v-p="['externaladmin:picture:publicGallery:imageUpdateCategory']"
            />
            <ChangeOriginPic
              :data="row"
              :sup_this="sup_this"
              :isPublic="true"
              v-p="['externaladmin:picture:publicGallery:imageUpdate']"
            />
            <Disable
              type="1"
              :data="row"
              :sup_this="sup_this"
              v-p="['externaladmin:picture:publicGallery:batchCancelDisable']"
            ></Disable>
          </template>
        </el-table-column>
      </template>
    </BasePicListPage>
    <sign
      v-if="visible"
      :visible.sync="visible"
      :selectedData="selectedData"
      :sup_this="sup_this"
      @confirmHandler="confirmHandler"
    />
    <assignDistributor v-if="canAssign" ref="assignDistributor" type="picture"></assignDistributor>
  </section>
</template>

<script>
import CreateAssignGroup from '@/views/components/createAssignGroup'
import BasePicListPage from '../basePicListPage'

import { publicGalleryField as formField } from './field'
import { publicImageListCols as cols } from './cols'
import UploadImg from '../basePicListPage/module/uploadImg'
import Disable from '../module/disable'
import ChangeCate from '../module/changeCate'
import ChangeOriginPic from '../module/changeOriginPic'
import BulkDisable from '../module/bulkDisable'
import BulkChangeCate from '../module/bulkChangeCate'
import { mapGetters } from 'vuex'
import assignDistributorMixin from '@/views/product/module/assignDistributor/mixin'
import sign from './sign'

export default {
  components: {
    BasePicListPage,
    ChangeOriginPic,
    UploadImg,
    Disable,
    ChangeCate,
    BulkDisable,
    BulkChangeCate,
    CreateAssignGroup,
    sign
  },
  mixins: [assignDistributorMixin],
  data() {
    return {
      visible: false,
      selectedData: [],
      sup_this: this,
      resetUrl: '/externaladmin/productService/image/openList',
      canAssign: this.checkPermission('externaladmin:picture:publicGallery:imageList:assign'),

      hasCheckAllData4: this.checkPermission(['externaladmin:picture:publicGallery:imageList:checkAll']),
      hasCreateAssignGroup: this.checkPermission(['externaladmin:picture:publicGallery:imageList:createAssignGroup']),
      hasDoSubmit: this.checkPermission(['externaladmin:picture:publicGallery:imageList:createAssignGroup:add'])
    }
  },
  computed: {
    ...mapGetters(['isMain']),
    cols({ isMain }) {
      if (!isMain) {
        return cols.filter((item) => item.value !== 'manager')
      }
      return cols
    },
    formField({ isMain }) {
      if (!isMain) {
        return formField.filter((item) => item.value !== 'manager')
      }
      return formField
    }
  },
  methods: {
    handleTort() {
      this.selectedData = this.$refs.picRef.selectedData
      if (!this.selectedData.length) return this.$message.warning('请先选择数据再进行操作')
      this.visible = true
    },
    confirmHandler() {
      this.$refs.picRef.init()
      this.$message.success('操作成功')
    }
  }
}
</script>

<style></style>
