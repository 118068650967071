<template>
  <div class="changeStateBtn line">
    <el-button :type="option.btnType" size="small" @click="option.onclick">修改订单状态</el-button>
    <BaseDialog
      title="修改订单状态"
      :dialogVisible.sync="changeStateDialogVisible"
      v-bind="option.dialogAttrs"
      @openHandle="option.onopen"
    >
      <div v-if="option.isSingle" class="changeStateDialogContent" style="padding-top: 12px">
        <span>订单编号: {{ data.orderCode }}</span>
        <span v-if="!isTemuOrder" >物流渠道: {{ getLabel('expressCompanyId', data.expressCompanyId) }}</span>
        <span v-if="!isTemuOrder">运单号: {{ data.expressWaybillCode }}</span>
        <span>业务员: {{ data.userName || data.nickName }}</span>
        <div style="margin: 16px 0 9px 0">
          当前状态
          <span class="changeStateBtn-color">{{ getLabel(isTemuOrder? 'temuOrderStatus' : 'orderStatus', data.orderStatus) }}</span>
        </div>
      </div>
      <el-form :model="form" class="changeStateBtn-from" size="mini">
        <el-form-item label="修改订单状态">
          <el-select v-model="form.orderStatus" placeholder="请选择">
            <el-option
              v-for="({ label, value }, index) in orderStatusData"
              :key="index"
              :label="label"
              :value="Number(value)"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <baseTable v-if="option.isBatch" :data="tableData" :option="tableOption">
          <template #userName="{ row }">
              <span>{{ row.userName || row.nickName}}</span>
          </template>

      </baseTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeStateDialogVisible = false">取 消</el-button>
        <baseButton size type="primary" @click="changeStateDialog">确 定</baseButton>
      </span>
    </BaseDialog>
  </div>
</template>

<script>
import { listUpdate, updateStatusBatch } from '@/api/order/orderListData'
import { getLabel } from '@/components/avue/utils/util'
import { cloneDeep, map, isArray, chain } from 'lodash'
import { mapGetters } from 'vuex'
import { getLabelOfOrderStatus } from '@/utils/constant'
import temuOrderListApi from '@/api/order/temuOrderList'

export default {
  props: {
    data: {
      type: Object | Array,
      required: true
    },
    sup_this: {
      type: Object,
      default() {
        return {}
      }
    },
    isTemuOrder:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      changeStateDialogVisible: false,
      form: {}
    }
  },
  computed: {
    ...mapGetters(['orderStatus', 'temuOrderStatus']),
    option() {
      const options = {
        default: {
          isSingle: true,
          btnType: 'text',
          dialogAttrs: {
            width: '30%'
          },
          onclick: this.showChangeStateDialogVisible,
          onopen: () => {
            this.form = cloneDeep(this.data)
          },
          changeApi: !this.isTemuOrder
            ? listUpdate
            : temuOrderListApi.update,
          getParams: () => {
            return  !this.isTemuOrder
              ?  {
                  orderStatus: this.form.orderStatus,
                  id: this.data.id
              }
              : {
                  orderStatus: this.form.orderStatus,
                  orderIdList: [this.data.id]
              }
          }
        },
        batch: {
          isBatch: true,
          btnType: 'primary',
          dialogAttrs: {
            width: '1000px',
            top: '5vh'
          },
          onclick: () => {
            if (!this.data.length) {
              this.$message.warning('请先选择订单')
              return
            }
            this.showChangeStateDialogVisible()
          },
          onopen: () => {
            this.form = {}
          },
          changeApi: !this.isTemuOrder ? updateStatusBatch: temuOrderListApi.update,
          getParams: () => {
            const { orderStatus } = this.form
            if (!orderStatus) {
              this.$message.warning('请先选择订单状态')
              throw '请先选择订单状态'
            }
            return {
              orderStatus,
              orderIdList: map(this.tableData, 'id')
            }
          }
        }
      }
      return isArray(this.data) ? options.batch : options.default
    },
    
    orderStatusData({ orderStatus, temuOrderStatus, isTemuOrder }){
      return isTemuOrder ? temuOrderStatus : orderStatus
    },

    tableData() {
      return chain(this.data)
        .map('orderInfo')
        .cloneDeep()
        .value()
    },
    tableOption({ isTemuOrder }) {
      return {
        pageSizes: [10, 20, 50],
        menu: false,
        column: [
          {
            label: '订单号',
            prop: 'orderCode'
          },
          {
            label: '物流渠道',
            prop: 'expressCompanyId',
            type: 'select',
            hide: isTemuOrder
          },
          {
            label: '运单号',
            prop: 'expressWaybillCode',
            hide: isTemuOrder
          },
          {
            label: '业务员',
            prop: 'userName'
          },
          {
            label: '订单状态',
            prop: 'orderStatus',
            width: 150,
            formatter: getLabelOfOrderStatus
          }
        ]
      }
    }
  },
  methods: {
    getLabel,
    showChangeStateDialogVisible() {
      this.changeStateDialogVisible = true
    },
    async changeStateDialog() {
      const { getParams, changeApi } = this.option
      const params = getParams()
      const { code } = await changeApi(params)
      if (code == 0) {
        if (this.sup_this) {
          this.sup_this.init()
        }
        this.$message.success('更改状态成功')
      } else {
        this.$message.error('更改状态失败')
      }
      this.changeStateDialogVisible = false
    }
  }
}
</script>

<style lang="scss">
.changeStateBtn {
  display: inline-block;
}
.changeStateDialogContent {
  background-color: #ebf0f8;
  padding: 5px;
  span {
    margin-right: 20px;
  }
}
.changeStateBtn-from {
  margin-top: 20px;
}

.changeStateBtn-color {
  color: orange;
}
</style>
