<template>
  <div class="formPart">
    <el-form size="mini" :model="query" label-width="150" inline uiid="zd-search">
      <template v-for="({ label, placeholder, model, type, dicData, feature }, index) in orderFormData">
        <el-form-item :key="index" v-if="type === 'select'" :label="label">
          <el-select v-model="query[model]" :placeholder="placeholder" clearable @change="toQuery">
            <el-option v-for="item in dicData" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :key="index" v-else-if="feature === 'batch'" :label="label">
          <el-input v-model="shipmentNoList" :placeholder="placeholder" clearable @change="onBatchHandler"> </el-input>
        </el-form-item>

        <el-form-item :key="index" v-else-if="model === 'shopId'" :label="label">
          <el-cascader
            placeholder="请选择"
            clearable
            filterable
            :options="shopOptions"
            :show-all-levels="false"
            :props="{ checkStrictly: false }"
            v-model="filterShop"
          ></el-cascader>
        </el-form-item>

        <el-form-item :key="index" v-else-if="model === 'productCategoryId'" :label="label">
          <ProtoTypeTreeSelect
            size="mini"
            style="width: 174px"
            placeholder="请选择"
            :selectedValue.sync="query[model]"
            @change="to"
          />
        </el-form-item>

        <el-form-item :key="index" v-else-if="type === 'cascader'" :label="label">
          <avue-crud-cascader v-model="query[model]" :dic="dicData" @change="toQuery"></avue-crud-cascader>
        </el-form-item>
        <el-form-item :key="index" v-else-if="type === 'daterange'" :label="label">
          <SaleDateTime :model="query" :timeProp="model" @change="toQuery" />
        </el-form-item>
        <el-form-item :key="index" v-else-if="type === 'multiple'" :label="label">
          <selectInput style="width: 350px;" clear :uiid="`zd-${model}`" :form="query" :dic="dicData" @change="toQuery"></selectInput>
        </el-form-item>
        <el-form-item :key="index" v-else-if="label === 'button'">
          <el-button uiid="zd-submit" class="el-icon-search" type="primary" @click="toQuery"></el-button>
        </el-form-item>
        <el-form-item :key="index" v-else :label="label">
          <el-input
            :placeholder="placeholder"
            clearable
            v-model="query[model]"
            @keyup.enter.native="toQuery"
          ></el-input>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
import ProtoTypeTreeSelect from '@/components/protoTypeTreeSelect'
import selectInput from '@/components/base/baseForm1/module/selectInput'
import SaleDateTime from '@/components/saleDateTime'
import { commonEheaderMixin } from '@/mixins'
import {
  ORDER_TYPE_DIC,
  TEMU_ORDER_STATUS_DIC
} from '@/utils/constant'
import { mapGetters, mapActions } from 'vuex'
import { deepClone, validateDiffData } from '@/components/avue/utils/util'
import { validatenull } from '@/components/avue/utils/validate'
import { getArrFromStr } from '@/utils'

let inDispatchFormData = [
  { label: '订单号', placeholder: '输入订单号', model: 'orderCode' },
  {
    label: '运单号',
    placeholder: '输入运单号',
    model: 'expressWaybillCode'
  },
  { label: '分销商', placeholder: '输入分销商名称', model: 'userName' },
  {
    label: '产品名称',
    placeholder: '输入产品名称',
    model: 'productCnName'
  },
  {
    label: '款式名称',
    placeholder: '输入款式',
    model: 'structName'
  },
  {
    label: '内部SKU',
    placeholder: '输入内部SKU',
    model: 'inSku'
  },
  {
    label: '自定义SKU',
    placeholder: '输入自定义SKU',
    model: 'customSku'
  },
  {
    label: '刀版图状态',
    model: 'isKnifeLayoutCreated',
    type: 'select',
    dicData: [
      {
        label: '已全部生成',
        value: '1'
      },
      {
        label: '未全部生成',
        value: '0'
      }
    ],
    placeholder: '请选择'
  },
  {
    label: '导入日期',
    model: 'importTime',
    type: 'daterange'
  },
  {
    label: '订单类别',
    model: 'orderType',
    type: 'select',
    dicData: ORDER_TYPE_DIC,
    placeholder: '请选择'
  },
  {
    label: '选择店铺',
    model: 'shopId',
    type: 'cascader',
    dicData: null,
    placeholder: '请选择店铺'
  },
  {
    label: '工厂分类',
    model: 'factoryCategoryId',
    type: 'select',
    dicData: null,
    placeholder: '请选择工厂分类'
  },
  { label: 'button' }
]
let orderFormData = [
  { label: '导入日期', model: 'create_time', type: 'daterange' },
  { label: '备货单创建时间', model: 'purchaseOrderCreate_time', type: 'daterange' },
  { label: '分销商', placeholder: '输入分销商名称', model: 'nickName' },
  { label: '订单号', placeholder: '输入订单号', model: 'orderCodeList' },
  {
    label: '选择店铺',
    model: 'shopId',
    type: 'cascader',
    dicData: null,
    placeholder: '请选择店铺'
  },
  {
    label: '订单状态',
    model: 'orderStatus',
    type: 'select',
    dicData: TEMU_ORDER_STATUS_DIC,
    placeholder: '请选择'
  },
  {
    label: 'SKU', placeholder: '输入SKU', model: 'productCustomSkuCodeList'
  },
  { label: 'button' }
]
let logisticsChannelProps = ['isDockApi', 'expressCompanyId', 'expressShippingMethodId']
let logisticsChannelObj = {}
logisticsChannelProps.forEach((prop) => (logisticsChannelObj[prop] = undefined))

export default {
  mixins: [commonEheaderMixin],
  components: {
    selectInput,
    SaleDateTime,
    ProtoTypeTreeSelect
  },
  props: {
    query: {
      type: Object,
      required: true
    },
    orderStatus: {
      type: String
    },
    inDispatch: {
      type: Boolean,
      default: true
    },
    postData: {
      type: Object
    },
    isDeleted: Boolean
  },
  data() {
    return {
      filterShop: [],
      formField: [{ prop: 'importTime' }],
      isChannelRequested: false,
      logisticsChannelList: [],
      logisticsChannelListLoading: false,
      shipmentNoList: ''
    }
  },
  computed: {
    ...mapGetters(['proCategory', 'waybillChangeStatus', 'shop', 'factoryCategory']),
    orderFormData({ inDispatch, isDeleted, orderStatus }) {
      let id = this.$route.query.id
      if (inDispatch) {
        let factoryCategory = inDispatchFormData.find((item) => item.label === '工厂分类')
        if (factoryCategory) {
          factoryCategory.dicData = this.factoryCategory
        }
        if (id) return deepClone(inDispatchFormData.filter((item) => item.model != 'shopId'))
        return inDispatchFormData
      } else {
        let formData = deepClone(orderFormData)
        if (id) {
          formData = orderFormData.filter((item) => item.model != 'shopId')
        }
        // 后台-全部订单-全部订单中，增加筛选项：订单状态
        if (isDeleted || orderStatus !== '') {
          return formData.filter((item) => item.model !== 'orderStatus')
        }
        return formData
      }
    },
    shopTypeMap() {
      let obj = {}
      this.shop.platforms.map((item) => {
        obj[item.code] = item.name
      })
      return obj
    },
    shopOptions() {
      let dic = []
      let data = this.shop.shops
      Object.keys(this.shopTypeMap).filter(item => item == 8 ).forEach((code) => {
        let child = data.filter((item) => item.shopType == code)
        let children = []
        child.forEach((item) => {
          children.push({
            value: item.id,
            label: item.shopName
          })
        })
        dic.push({
          value: code,
          label: this.shopTypeMap[code],
          children
        })
      })
      data.forEach((item) => {
        let children = []
        children.push()
      })
      return dic
    }
  },
  async created() {
    await Promise.all([this.GetPlatforms(), this.GetShop()])
    // this.getLogisticsChannelList(this.postData)
  },
  watch: {
    postData: {
      handler(n, o) {
        let diffDataKeys = Object.keys(validateDiffData(n, o))
        let isLogisticsChannelChange = diffDataKeys.some((key) => logisticsChannelProps.includes(key))
        //console.log(diffDataKeys, validatenull(diffDataKeys) || isLogisticsChannelChange)
        //console.log(33333, logisticsChannelObj, n, o, this.$refs.logisticsChannelCascader)

        if (validatenull(diffDataKeys) || isLogisticsChannelChange) return

        if (
          diffDataKeys.includes('isDockApi') ||
          diffDataKeys.includes('expressCompanyId') ||
          diffDataKeys.includes('expressShippingMethodId')
        ) {
          Object.assign(n, logisticsChannelObj)
          Object.assign(this.query, logisticsChannelObj)
        }

        this.addParmasId()
        // this.getLogisticsChannelList(n)
        this.isChannelRequested = false
      },
      immediate: true,
      deep: true
    },
    filterShop(n) {
      //this.$set(this.query, 'shopId', n?.[1])
      Object.assign(this.query, { shopId: n?.[1] })
      this.toQuery()
    }
  },
  methods: {
    ...mapActions(['GetPlatforms', 'GetShop']),
    to() {
      this.toQuery()
    },
    addParmasId() {
      let id = this.$route.query.id
      if (id) {
        Object.assign(this.query, { shopId: id })
      }
    },
    onBatchHandler() {
      this.query.shipmentNoList = getArrFromStr(this.shipmentNoList)
      this.toQuery()
    }
  }
}
</script>

<style lang="scss" scoped>
.formPart {
  margin: 20px 0;
  background-color: #f7f8fa;
  padding: 20px 30px 5px 20px;
  .button-col {
    text-align: right;
  }
}
::v-deep {
  .vue-treeselect__control {
    height: 26px;
    line-height: 26px;
  }
}
</style>
