<template>
  <div class="tablePart">
    <el-table
      ref="table"
      uiid="zd-table"
      v-loading="tableLoading"
      :data="finalData"
      border
      :span-method="spanMethod"
      :cell-class-name="tableCellClassName"
      :row-class-name="rowClassName"
      @selection-change="selectionChange"
    >
      <el-table-column type="selection" :selectable="selectable"></el-table-column>
      <el-table-column label="订单信息" prop="orderCode" min-width="200">
        <template slot-scope="{ row }">
          <template v-if="row.$isTotal"> 已选中订单数量: {{ selectionTotal }} </template>
          <template v-else-if="row.isHeader">
            <div class="flex-between">
              <div>
                <span uiid="zd-orderCode">
                  订单号：<hoverCopy :value="row.orderCode" class="text-primary">{{ row.orderCode }}</hoverCopy>
                </span>
                <span class="ml20"
                  >分销商：{{ row.orderInfo.nickName || '暂无' }}
                  <span v-if="row.orderInfo.userStatus === 2" style="color: red">(已注销)</span>
                </span>
                <span class="ml20" v-if="row.orderInfo.childrenUserName"
                  >子账号：{{ row.orderInfo.childrenUserName || '暂无'
                  }}<span v-if="row.orderInfo.userStatus === 2" style="color: red">(已注销)</span></span
                >
              </div>
              <span v-if="!getExpressWaybillFilePath(row.orderInfo)">无面单</span>
              <el-button v-else-if="row.orderInfo.expressWaybillDownloadTime != null" type="warning" size="mini"
                >已下载面单</el-button
              >
              <span v-else>未下载面单</span>
            </div>
          </template>
          <template v-else>
            <productInfo
              :data="row.orderItemDTOList"
              :orderData="row"
              :doLayout="doLayout"
              :sup_this="sup_this"
            ></productInfo>
          </template>
        </template>
      </el-table-column>

      <el-table-column label="物流信息" min-width="200">
        <template slot-scope="{ row }">
          <template
            v-if="row.orderType !== ORDER_TYPE_FBA || (row.orderType === ORDER_TYPE_FBA && row.freightPaymentTime)"
          >
            <!-- 运单申请中 -->
            <template v-if="row.expressWaybillIsCreated === 8">
              <span style="color: red">运单申请中</span>
            </template>
            <!-- 运单错误 --><!-- 已过期且为系统导入 -->
            <template
              v-else-if="
                row.expressWaybillIsCreated === 9 ||
                (`${row.orderStatus}` === EXPIRED && row.waybillType === WAYBILL_TYPE_SYS)
              "
            >
              <div>运单申请异常</div>
              <span>物流:</span> {{ expressName(row) }}
              <br />
              <span>失败原因:</span> {{ errorInfo(row) }}
              <br />
            </template>
            <!-- 运单号正常 -->
            <template v-else>
              <template v-if="row.waybillType === WAYBILL_TYPE_HAND">
                <el-tooltip class="item" effect="dark" content="外部导入" placement="top">
                  <el-button type="warning" size="mini">
                    {{ expressName(row) }}
                  </el-button>
                </el-tooltip>
                <template v-if="row.changeRecordDTOList && row.changeRecordDTOList.length">
                  <div v-for="{ expressWaybillCodeOld, id } in row.changeRecordDTOList" :key="id">
                    <del style="color: rgb(56, 65, 219)">{{ expressWaybillCodeOld }}</del>
                  </div>
                </template>
              </template>
              <div v-else>
                {{ expressName(row) }}
                <template v-if="row.changeRecordDTOList && row.changeRecordDTOList.length">
                  <div v-for="{ expressWaybillCodeOld, id } in row.changeRecordDTOList" :key="id">
                    <del style="color: rgb(56, 65, 219)">{{ expressWaybillCodeOld }}</del>
                  </div>
                </template>
              </div>
              <div style="color: rgb(56, 65, 219)" v-if="getExpressWaybillCode(row)">
                <hoverCopy
                  :value="getExpressWaybillCode(row)"
                  style="color: #3841db; text-indent: -8px"
                  :style="{ color: row.orderStatus === 29 ? '#606266' : '#3841db' }"
                  >「{{ getExpressWaybillCode(row) }}」</hoverCopy
                >
              </div>
            </template>
            <div>
              <selectExpressCompanyName
                :sup_this="{ selectionDataAllArr: [row], init: sup_this.init }"
                :singleData="[singleData]"
                ref="dialog"
                v-if="
                  ((row.orderStatus === 42 &&
                    isShow('externaladmin:orderService:order:tryWaybillNoCreate:inProduct')) ||
                    (row.orderStatus === 29 && isShow('externaladmin:orderService:order:tryWaybillNoCreate:expired')) ||
                    (row.expressWaybillIsCreated === 0 &&
                      isShow('externaladmin:orderManage:orderList:waitCreateWaybill:expressWaybillIsCreated0')) ||
                    (row.expressWaybillIsCreated === 9 &&
                      isShow('externaladmin:orderManage:orderList:waitCreateWaybill:expressWaybillIsCreated9'))) &&
                  (row.waybillType === WAYBILL_TYPE_SYS || row.waybillType === WAYBILL_TYPE_NO) &&
                  !isDeleted(row) &&
                  !isFbaOrder(row)
                "
              >
                <el-button uiid="zd-selectExpress" size="small" @click="showDialog(row)">重新申请</el-button>
              </selectExpressCompanyName>
            </div>
          </template>
        </template>
      </el-table-column>

      <el-table-column label="支付信息" min-width="200" #default="{ row }">
        <div>
          <p>货款：{{ getPaymentProduct(row) }}</p>
          <p v-if="row.freightPaymentTime && row.orderType == fbaOrder">运费：{{ getTemplatePrice(row) }}</p>
          <p v-if="row.orderType != fbaOrder">运费：{{ getTemplatePrice(row) }}</p>
          <p>总计：{{ totalPrice(row) || '0' }}</p>
        </div>
      </el-table-column>
      <el-table-column
        v-if="orderStatus === DELIVER"
        label="包裹重量"
        prop="sendOutWeight"
        :formatter="sendOutWeightFormatter"
      ></el-table-column>

      <!--<el-table-column label="刀版图状态">
        <template slot-scope="{ row: { isKnifeLayoutCreated }, row }">
          {{ KnifeLayout(isKnifeLayoutCreated) }}
          <ColorTextBtn
            v-if="KnifeLayout(isKnifeLayoutCreated) === '已全部生成' && !isDeleted(row)"
            @click="viewKnifeLayout(row)"
            >查看</ColorTextBtn
          >
        </template>
      </el-table-column>-->
      <el-table-column label="订单状态" :formatter="getLabelOfOrderStatus"></el-table-column>
      <el-table-column label="是否删除" prop="isDeleted" :formatter="isDeletedFormatter"></el-table-column>

      <el-table-column label="订单类别">
        <template slot-scope="{ row: { orderType } }">
          {{ findByvalue(ORDER_TYPE_DIC, orderType) }}
        </template>
      </el-table-column>
      <el-table-column label="是否是购物车订单">
        <template slot-scope="{ row: { isCartOrder } }">
          {{ findByvalue(CART_ORDER_DIC, isCartOrder) }}
        </template>
      </el-table-column>

      <el-table-column label="物流到期时间">
        <template slot-scope="{ row }">
          <template v-if="row.orderType !== ORDER_TYPE_FBA">
            <div v-if="parseInt(row.expressExpireTime) <= 3">
              {{ row.expressExpireTime }}
              <el-tag type="danger" size="mini" class="tixing">临近提醒</el-tag>
            </div>
            <div v-else>
              {{ row.expressExpireTime }}
            </div>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="时间">
        <template slot-scope="{ row }">
          <el-tooltip class="item" effect="dark" placement="top">
            <template #content>
              <div v-for="time in row.$sortTime" :key="time.prop" class="icon-circle">
                {{ time.label }}：{{ time.value }}
              </div>
            </template>
            <div v-if="row.$lastTime">{{ row.$lastTime.label }}：{{ row.$lastTime.value }}</div>
          </el-tooltip>

          <div
            v-if="
              row.orderType !== ORDER_TYPE_FBA &&
              (row.waybillChangeStatus == IS_WAYBILL_CHANGE_YES ||
                row.waybillChangeStatus == IS_WAYBILL_CHANGE_BACKEND_YES)
            "
          >
            <div>换单时间：{{ parseTime(row.changeTime) || '暂无' }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="250">
        <template slot-scope="scoped" v-if="!isDeleted(scoped.row)">
          <changeStateBtn :data="scoped.row" :sup_this="sup_this" v-if="permission.update"></changeStateBtn>
          <Edit
            v-if="permission.edit && canEdit(scoped.row)"
            :data="scoped.row"
            :type="1"
            :id="scoped.row.id"
            :sup_this="sup_this"
            :orderStatus="orderStatus"
          >
            编辑
          </Edit>
          <Edit :data="scoped.row" :isProTable="true" :id="scoped.row.id" v-if="permission.detail"> 查看详情 </Edit>

          <PrintExpressInvoice
            class="line"
            :data="scoped.row"
            :id="scoped.row.id"
            v-if="isShowPrintExpressInvoice(scoped.row)"
          />
        </template>
      </el-table-column>

      <template slot="empty">暂无数据</template>
    </el-table>
    <viewKnifeLayout :visible.sync="dialogVisible" :data="dialogData"></viewKnifeLayout>
  </div>
</template>

<script>
import { tableFormDataObj } from './tablePartData'
import viewKnifeLayout from '@/views/order/cutoffBatch/viewDetails/viewKnifeLayout.vue'
import changeStateBtn from './changeStateBtn'
import orderDetile from './orderDetile'
import editBtn from './editBtn'
import Edit from './edit'
import PrintExpressInvoice from './printExpressInvoice'

import productInfo from '../productInfo'
import hoverCopy from '@/views/components/hoverCopy'
import selectExpressCompanyName from './selectExpressCompanyName'
import {
  WAIT_RELATION,
  WAIT_GENERATE_WAYBILL,
  WAIT_DISPATCH, // 待排单
  IN_DISPATCH, // 排单中
  WAYBILL_TYPE_HAND,
  getExpressWaybillCode,
  EXPIRED,
  IN_PRODUCTION, // 生产中
  WAYBILL_TYPE_SYS,
  WAYBILL_TYPE_NO,
  IS_WAYBILL_CHANGE_YES, //运单换单
  IS_WAYBILL_CHANGE_BACKEND_YES, //后台换单
  ORDER_TYPE_FBA,
  getLabelOfOrderStatus,
  ORDER_IS_DELETED_YES,
  DELIVER,
  ORDER_TYPE_SMT
} from '@/utils/constant'
import { getLabel, findByvalue } from '@/components/avue/utils/util'
import { parseTime, accAdd, numberAdd } from '@/utils'
import { checkPermission } from '@/utils/permission'
import { ORDER_TYPE_DIC, CART_ORDER_DIC } from '@/utils/constant/orderConst'
import TemplateDialog from '@/views/fedex/formwork/module/templateDialog.vue'

import { isPaidOrder, isWaybillIsCreatingOrder } from '@/views/order/utils'

export default {
  components: {
    hoverCopy,
    productInfo,
    changeStateBtn,
    orderDetile,
    editBtn,
    Edit,
    PrintExpressInvoice, //打印发票

    selectExpressCompanyName,
    viewKnifeLayout,
    TemplateDialog
  },
  props: {
    orderListData: {
      type: Array,
      required: true,
      default: []
    },

    permission: {
      type: Object
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    sup_this: {
      type: Object,
      required: true
    },
    orderStatus: {
      type: String
    },
    isWaybillCreate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ORDER_TYPE_DIC,
      CART_ORDER_DIC,
      DELIVER,
      ORDER_IS_DELETED_YES,
      ORDER_TYPE_FBA,
      factoryData: [],
      WAYBILL_TYPE_HAND,
      EXPIRED,
      WAYBILL_TYPE_SYS,
      WAYBILL_TYPE_NO,
      IS_WAYBILL_CHANGE_YES, //运单换单
      IS_WAYBILL_CHANGE_BACKEND_YES, //后台换单
      // 英国税号
      britain_duty_paragraph: '',
      // ioss 税号
      ioss_duty_paragraph: '',
      IN_PRODUCTION,
      WAIT_DISPATCH, // 待排单
      IN_DISPATCH, // 排单中
      dialogVisible: false,
      dialogData: null,
      singleData: {},
      fbaOrder: 2,
      ordinaryOrder: 1
    }
  },
  computed: {
    totalPrice({ getTemplatePrice, fbaOrder }) {
      return (row) => {
        return (Number(this.getPaymentProduct(row)) + Number(getTemplatePrice(row))).toFixed(2)
      }
    },

    isFbaOrder() {
      return (row) => {
        return row.orderType === ORDER_TYPE_FBA
      }
    },

    //货款
    getPaymentProduct() {
      return (row) => {
        return $GET(row, 'externalOrderFinance.paymentProduct', 0)
      }
    },

    errorInfo() {
      return (row) => {
        return $GET(row, 'externalOrderLogistics.errorInfo', '')
      }
    },

    getExpressWaybillFilePath() {
      return (row) => {
        return $GET(row, 'externalOrderLogistics.expressWaybillFilePath', null)
      }
    },

    //物流
    getTemplatePrice() {
      // 需要参与退补运费的计算
      return (row) => {
        let extendFee = this.calcExtendFee(row)
        return accAdd($GET(row, 'externalOrderFinance.paymentFreight', 0), extendFee)
      }
    },

    // 是否展示打印发票按钮
    isShowPrintExpressInvoice() {
      return (row) => {
        //expressCompanyId == 1 为联邦快递
        return (
          checkPermission('externaladmin:orderManage:orderList:all:printExpressInvoice') &&
          (row.expressCompanyId === 1 || row.expressCompanyId === 7)
        )
      }
    },

    isShow() {
      return (data) => {
        return checkPermission(data)
      }
    },
    finalData() {
      const data =
        this.orderListData && this.orderListData.length
          ? [
              {
                $isTotal: true
              }
            ].concat(this.orderListData)
          : null
      return data
    },
    selectionTotal({ factoryData }) {
      return factoryData.length
    },
    canEdit() {
      return (row) => {
        const { orderStatus } = row
        const temArr = [WAIT_RELATION, WAIT_GENERATE_WAYBILL, WAIT_DISPATCH, IN_DISPATCH]
        return true
        return temArr.includes(orderStatus)
      }
    },
    KnifeLayout() {
      return (data) => {
        if (data == '0') {
          return '未全部生成'
        } else {
          return '已全部生成'
        }
      }
    },
    expressCompany() {
      return (data) => {
        switch (data) {
          case 1:
            return '申通'
            break
          case 4:
            return '顺丰'
            break
          case 2:
            return 'fedex'
            break
          case 6:
            return '极兔'
            break
        }
      }
    },
    tableFormData({ orderStatus }) {
      return tableFormDataObj[orderStatus] || tableFormDataObj.default
    }
  },
  methods: {
    getLabel,
    findByvalue,
    parseTime,
    getExpressWaybillCode,
    getLabelOfOrderStatus,
    //计算退补运费
    calcExtendFee(data) {
      let diffFreightList = []
      let { waybillApplyRecordDTOList: changeRecordDTOList } = data
      if (isWaybillIsCreatingOrder(data) && changeRecordDTOList) {
        changeRecordDTOList = [...changeRecordDTOList, { realTemplateFreight: data.externalOrderFinance?.finalFreight }]
      }
      if (isPaidOrder(data) && changeRecordDTOList?.length > 1) {
        changeRecordDTOList.slice(1).forEach((item, index) => {
          diffFreightList.push(
            this.$math.subtract(item.realTemplateFreight, changeRecordDTOList[index].realTemplateFreight)
          )
        })
        diffFreightList = diffFreightList.filter((item) => item !== 0)
      }
      return diffFreightList.length ? numberAdd(diffFreightList) : 0
    },

    expressName(scoped) {
      if (scoped.orderType == ORDER_TYPE_SMT) {
        return this.logistics_service_name(scoped?.externalOrderLogistics?.expressThird)
      }
      return scoped.expressShippingMethodCnName || scoped.expressCompanyName || '暂无'
    },
    logistics_service_name(expressThird) {
      try {
        return JSON.parse(expressThird)?.logistics_service_name
      } catch (error) {
        return '暂无'
      }
    },
    showDialog(data) {
      this.singleData = data
      this.$nextTick(function () {
        this.$refs.dialog.to()
      })
    },
    selectionChange(selection) {
      this.factoryData = selection
      this.$emit('takeFactory', selection)
    },
    spanMethod({ row, columnIndex }) {
      if (row.$isTotal) {
        if (columnIndex === 1) {
          return [1, 11]
        } else {
          return [0, 0]
        }
      } else if (row.isHeader) {
        if (columnIndex === 0) {
          return [1, 1]
        } else if (columnIndex === 1) {
          return [1, 11]
        } else {
          return [0, 0]
        }
      } else {
        if (columnIndex === 0) {
          return [0, 0]
        } else if (columnIndex === 1) {
          return [1, 2]
        }
      }
      return [1, 1]
    },
    tableCellClassName(params) {
      let { cellClassName } = this
      if (typeof cellClassName === 'function') cellClassName = cellClassName(params)
      let s
      let { column, rowIndex } = params
      if (column.type === 'selection') {
        s = `uiid-zd-${rowIndex}-selection`
      } else {
        s = `uiid-zd-${rowIndex}-${column.property}`
      }
      return [cellClassName, s].filter(Boolean).join(' ')
    },
    rowClassName({ row }) {
      if (row.$isTotal) {
        return this.selectionTotal ? 'total-row' : 'none'
      }
      if (row.isHeader) return 'hover-row-style is-visible'
    },
    selectable(row) {
      return row.isHeader
      //&& !this.isDeleted(row.orderInfo)
    },
    doLayout() {
      return this.$refs.table.doLayout()
    },
    viewKnifeLayout(row) {
      this.dialogVisible = true
      this.dialogData = row
    },

    isDeleted(row) {
      return row.isDeleted === ORDER_IS_DELETED_YES
    },
    isDeletedFormatter(row) {
      return this.isDeleted(row) ? '是' : '否'
    },
    sendOutWeightFormatter(row, column, value) {
      return `${value}kg`
    }
  }
}
</script>

<style lang="scss" scoped>
.tablePart {
  margin-top: 20px;
}
.tixing {
  margin-left: 10px;
}
.icon-circle {
  &:nth-last-child(2) {
    &::before {
      background-color: #67c23a;
    }
  }
}
</style>
