/* eslint-disable */
import request from '@/service/request'
import { validatenull } from '@/components/avue/utils/validate'
import { requestLimitNum, requestQueue } from '@/utils'

import {ORDER_STATUS_DIC, TEMU_ORDER_STATUS_DIC} from '@/utils/constant/businessConst'
import { ORDER_TYPE_DIC, WAYBILL_CHANGE_STATUS_DIC } from '@/utils/constant/orderConst'
import {
  MENU_TYPE_MENU,
  MENU_TYPE_BTN,
  MENU_TYPE_OTHER,
  MENU_DIC,
  CLIENT_TYPE_EXTERNAL_ADMIN,
  CLIENT_TYPE_EXTERNAL_BUSINESS,
  getXMenuType
} from '@/utils/constant/menuConst'
import { getToken } from '@/utils/auth'
import { REQUEST_ALL_DATA } from '@/utils/constant/requestConst'
import {
  PAYMENT_TYPE_DIC,
  HANDLE_STATUS_DIC,
  HANDLE_TYPE_DIC,
  REFUND_REASON_TYPE_DIC,
  APPROVE_STATUS_RECHARGE_DIC,
  APPROVE_STATUS_DIC
} from '@/utils/constant/financialConst'
import { validData } from '@/components/avue/utils/util'

function getDic(option) {
  if (!getToken()) return Promise.reject()
  return request({
    headers: getXMenuType(),
    method: 'post',
    ...option,
    data: {
      ...REQUEST_ALL_DATA,
      ...option.data
    }
  })
}

// 当type值为以下任意一项，设置dicData和props
let hasDicType = ['select', 'radio', 'checkbox', 'cascader']
const dic = {
  state: {
    approveStatusRecharge: {
      dicData: APPROVE_STATUS_RECHARGE_DIC
    },
    statusType: {
      dicData: APPROVE_STATUS_DIC
    },
    refundReasonType: {
      dicData: REFUND_REASON_TYPE_DIC
    },
    handleType: {
      dicData: HANDLE_TYPE_DIC
    },
    refundType: {
      dicData: [
        {
          label: '原路退回',
          value: 1
        }
        // {
        //   label: '退回余额',
        //   value: 2
        // }
      ]
    },
    approveStatus: {
      dicData: HANDLE_STATUS_DIC
    },
    paymentType: {
      dicData: PAYMENT_TYPE_DIC
    },
    compensateType: {
      dicData: [
        {
          label: '仅退货款',
          value: 1
        },
        {
          label: '仅退运费',
          value: 2
        },
        {
          label: '全额退款',
          value: 3
        }
      ]
    },
    responseType: {
      dicData: [
        {
          label: '供应商',
          value: 1
        },
        {
          label: '物流方',
          value: 2
        },
        {
          label: '团队',
          value: 3
        },
        {
          label: 'IT',
          value: 4
        },
        {
          label: '设计',
          value: 5
        },
        {
          label: '原创',
          value: 6
        }
      ]
    },
    payment: {
      dicData: [
        {
          label: '微信',
          value: 1
        },
        {
          label: '支付宝',
          value: 2
        }
      ]
    },
    cutoffStatus: {
      dicData: [
        {
          label: '否',
          value: 1
        },
        {
          label: '是',
          value: 2
        }
      ]
    },
    menuType: {
      dicData: [
        {
          label: '菜单',
          value: MENU_TYPE_MENU
        },
        {
          label: '按钮',
          value: MENU_TYPE_BTN
        },
        {
          label: '其它',
          value: MENU_TYPE_OTHER
        }
      ]
    },
    isShow: {
      dicData: [
        {
          label: '显示',
          value: 1
        },
        {
          label: '隐藏',
          value: 0
        }
      ]
    },
    menuStatus: {
      dicData: [
        {
          label: '正常',
          value: 1
        },
        {
          label: '停用',
          value: 0
        }
      ]
    },
    isPermissionControl: {
      dicData: [
        {
          label: '是',
          value: 1
        },
        {
          label: '否',
          value: 0
        }
      ]
    },
    sex: {
      dicData: [
        {
          label: '通用',
          value: 0
        },
        {
          label: '男',
          value: 1
        },
        {
          label: '女',
          value: 2
        },
        {
          label: '儿童',
          value: 3
        }
      ]
    },
    season: {
      dicData: [
        {
          label: '通用',
          value: 0
        },
        {
          label: '春',
          value: 1
        },
        {
          label: '夏',
          value: 2
        },
        {
          label: '秋',
          value: 3
        },
        {
          label: '冬',
          value: 4
        }
      ]
    },
    level: {
      dicData: [
        {
          label: '全部',
          value: 1
        },
        {
          label: '个人版',
          value: 2
        },
        {
          label: '创业版',
          value: 3
        },
        {
          label: '专业版',
          value: 4
        },
        {
          label: '企业版',
          value: 5
        }
      ]
    },
    declaration: {
      dicData: [
        {
          label: '含电',
          value: 'attrElectric'
        },
        {
          label: '含非液体化妆品',
          value: 'attrLiquid'
        },
        {
          label: '特货（敏感货）',
          value: 'attrSpecial'
        }
      ]
    },
    expressWaybillIsUploaded: {
      dicData: [
        {
          label: '已上传',
          value: '1'
        },
        {
          label: '未上传',
          value: '0'
        }
      ]
    },
    //换单状态
    waybillChangeStatus: {
      dicData: WAYBILL_CHANGE_STATUS_DIC
    },
    //订单类型
    orderTypeList: {
      dicData: [
        {
          label: '普通订单',
          value: '1'
        },
        {
          label: 'FBA订单',
          value: '2'
        }
      ]
    },
    orderStatus: {
      dicData: ORDER_STATUS_DIC
    },
    temuOrderStatus: {
      dicData: TEMU_ORDER_STATUS_DIC
    },
    orderType: {
      dicData: ORDER_TYPE_DIC
    },
    isKnifeLayoutCreated: {
      dicData: [
        {
          label: '已生成',
          value: 1
        },
        {
          label: '未生成',
          value: 0
        }
      ]
    },
    isCustomsDeclaredExported: {
      dicData: [
        {
          label: '生产中-未导出',
          value: '0'
        },
        {
          label: '生产中-已导出',
          value: '1'
        }
      ]
    },
    isAuthorized: {
      dicData: [
        {
          label: '全部',
          value: 'all'
        },
        {
          label: '已授权',
          value: '1'
        },
        {
          label: '未授权',
          value: '0'
        }
      ]
    },
    userNature: {
      dicData: [
        { label: '内部', value: 0 },
        { label: '余额', value: 1 },
        { label: '赊账', value: 2 }
      ]
    },

    //认证审查
    manualApproveStatus: {
      dicData: [
        {
          label: '全部',
          value: 'all'
        },
        {
          label: '未处理',
          value: '0'
        },
        {
          label: '已处理',
          value: '1'
        }
      ]
    },

    //实名认证
    isCertificated: {
      dicData: [
        {
          label: '全部',
          value: 'all'
        },
        {
          label: '实名认证',
          value: '1'
        },
        {
          label: '未实名认证',
          value: '0'
        }
      ]
    },

    //认证类型
    certificateType: {
      dicData: [
        {
          label: '全部',
          value: 'all'
        },
        {
          label: '个人用户',
          value: '1'
        },
        {
          label: '企业用户',
          value: '2'
        }
      ]
    },

    //刀版图文字层类型
    textLayerType: {
      dicData: [
        {
          label: '款式+颜色',
          value: 0
        },
        {
          label: '尺码+sku',
          value: 1
        }
      ]
    },

    // 营销管理-推荐专题设置 推荐时间
    durationType:{
      dicData:[
        {
          label: '长期',
          value: 0
        },
        {
          label: '限时显示',
          value: 1
        }
      ]
    },
     // 营销管理-添加产品 添加状态
     goodsStatus:{
      dicData:[
        {
          label: '',
          value: 1
        },
        {
          label: '已添加',
          value: 2
        }
      ]
    },

    //fba物流公司
    fbaExpressCompanyId: {
      url: '/externaladmin/systemService/expressCompany/list/',

      dicData: null,
      partParams: {
        isDeleted: {
          isDeleted: '0'
        }
      },
      partData: {
        isDeleted: null
      },
      props: {
        label: 'companyName',
        value: 'id'
      },
      data: {
        applyToOrderType: 2 //applyToOrderType=2 ,过滤出fba物流公司
      }
    },
    expressCompanyId: {
      url: '/externaladmin/systemService/expressCompany/list/',
      dicData: null,
      partParams: {
        isDeleted: {
          isDeleted: '0'
        }
      },
      partData: {
        isDeleted: null
      },
      props: {
        label: 'companyName',
        value: 'id'
      }
    },
    expressShippingMethodId: {
      url: '/externaladmin/systemService/expressShippingMethod/list',
      dicData: null,
      props: {
        label: 'cnName',
        value: 'id'
      }
    },
    expressShippingMethodList: {
      url: '/externaladmin/logisticsService/expressShippingMethod/list',
      data: {
        // 是否FBA物流渠道:1 普通物流， 2 FBA物流渠道
        isFbaOrOrdinaryShippingMethod: 2
      },
      dicData: null,
      props: {
        label: 'cnName',
        value: 'id'
      }
    },
    administrativeArea: {
      url: '/externaladmin/systemService/administrativeArea/complexList',
      dicData: null,
      props: {
        label: 'areaCnName',
        value: 'areaCode'
      }
    },
    category: {
      url: '/externaladmin/productService/imageCategory/list',
      dicData: null,
      props: {
        label: 'name',
        value: 'id'
      }
    },
    proCategory: {
      url: '/externaladmin/productService/productCategory/list',
      method: 'post',
      dicData: null,
      props: {
        label: 'name',
        value: 'id'
      }
    },
    gearsCategory: {
      url: '/externaladmin/productService/levelCategory/list',
      method: 'post',
      dicData: null,
      partParams: {
        enabled: {
          enabled: 1
        }
      },
      partData: {
        enabled: null
      },
      props: {
        label: 'name',
        value: 'id'
      }
    },
    factoryCategory: {
      url: '/externaladmin/productService/factoryCategory/nameList',
      method: 'post',
      dicData: null,
      props: {
        label: 'name',
        value: 'id',
        children: 'styleList'
      }
    },
    userSku: {
      // url: '/api/product/sku_map',
      method: 'get',
      dicData: null,
      props: {
        label: 'user_sku',
        value: 'user_sku'
      }
    },
    supplierId: {
      url: '/externaladmin/authService/supplier/supplierList',
      dicData: null,
      props: {
        label: 'supplierName',
        value: 'id'
      }
    },
    prim_color: {
      url: '/externaladmin/productService/color/colorList',
      dicData: null,
      props: {
        label: 'colorName',
        value: 'id'
      }
    },
    adminRoleList: {
      url: '/externaladmin/authService/role/adminRoleList',
      dicData: null,
      props: {
        label: 'roleName',
        value: 'id'
      }
    },
    businessRoleList: {
      url: '/externaladmin/authService/role/businessRoleList',
      dicData: null,
      props: {
        label: 'roleName',
        value: 'id'
      }
    },
    factoryRoleList: {
      url: '/externaladmin/authService/role/factoryRoleList',
      dicData: null,
      props: {
        label: 'roleName',
        value: 'id'
      }
    },
    adminMenuList: {
      url: '/externaladmin/authService/menu/treeMenuList',
      data: {
        ...REQUEST_ALL_DATA,
        clientType: CLIENT_TYPE_EXTERNAL_ADMIN
      },
      dicData: null,
      props: {
        label: 'menuName',
        value: 'id'
      }
    },
    businessMenuList: {
      url: '/externaladmin/authService/menu/treeMenuList',
      data: {
        ...REQUEST_ALL_DATA,
        clientType: CLIENT_TYPE_EXTERNAL_BUSINESS
      },
      dicData: null,
      props: {
        label: 'menuName',
        value: 'id'
      }
    },
    [MENU_DIC[CLIENT_TYPE_EXTERNAL_ADMIN].name]: {
      url: '/externaladmin/authService/menu/treeMenuList',
      data: {
        ...REQUEST_ALL_DATA,
        clientType: CLIENT_TYPE_EXTERNAL_ADMIN
      },
      dicData: null,
      props: {
        label: 'menuName',
        value: 'id'
      }
    },
    [MENU_DIC[CLIENT_TYPE_EXTERNAL_BUSINESS].name]: {
      url: '/externaladmin/authService/menu/treeMenuList',
      data: {
        ...REQUEST_ALL_DATA,
        clientType: CLIENT_TYPE_EXTERNAL_BUSINESS
      },
      dicData: null,
      props: {
        label: 'menuName',
        value: 'id'
      }
    },
    memberLevelList: {
      url: '/externaladmin/financeService/memberLevelList',
      dicData: null,
      props: {
        label: 'levelName',
        value: 'id'
      }
    },

    continentDictCode: {
      dictType: 'continentDictCode',
      dicData: null,
      props: {
        label: 'itemName',
        value: 'id'
      }
    },
    refundSeasonList: {
      dictType: 'refundSeasonList',
      dicData: null,
      props: {
        label: 'itemName',
        value: 'id'
      }
    },

    // 属性管理列表
    customfieldId: {
      url: '/externaladmin/orderService/exportFieldManagement/list',
      dicData: null,
      data: {
        templateUsageType: 2,
        isEnable: 1
      },
      props: {
        label: 'fieldName',
        value: 'id'
      }
    },

    // 属性管理列表(不同prop指向同一个dic)
    customfieldIdList: {
      url: '/externaladmin/orderService/exportFieldManagement/list',
      dicData: null,
      data: {
        templateUsageType: 2,
        isEnable: 1
      },
      props: {
        label: 'fieldName',
        value: 'id'
      }
    }
  },

  mutations: {
    // 设置dicData
    SET_DIC_DATA: (state, { prop, dicData } = {}) => {
      if (!prop || !state[prop]) return
      let { props, handleDicData, partParams, partData } = state[prop]

      state[prop].dicData = dicToCommon(dicData, props)
      if (handleDicData) handleDicData(dicData || [])

      if (partParams && partData && Array.isArray(dicData)) {
        let partKeys = Object.keys(partParams)
        partKeys.forEach((partKey) => {
          let partParam = partParams[partKey]
          if (typeof partParam === 'function') {
            partData[partKey] = partParam(dicData)
          } else {
            let partParamKeys = Object.keys(partParam)
            partData[partKey] = dicData.filter((dicItem) =>
              partParamKeys.every((key) => dicItem[key] === partParam[key])
            )
          }
        })
      }
    }
  },

  actions: {
    MultipleGetDic({ dispatch }, props = []) {
      return promiseAll(props.map(async (prop) => await dispatch('GetDic', prop)))
    },
    // 根据dictType获取字典
    GetDict({ state, commit }, dictType) {
      return new Promise(async (resolve) => {
        let res1 = await awaitResolve(
          getDic({
            url: '/externaladmin/systemService/dictionary/list',
            data: {
              dictType
            }
          })
        )
        if (!res1) return resolve([])

        let res2 = await awaitResolve(
          getDic({
            url: '/externaladmin/systemService/dictionaryItem/list',
            data: {
              dictId: res1?.detail[0]?.id
            }
          })
        )
        if (!res2) return resolve([])
        resolve(res2.detail || [])
      })
    },
    // 获取字典
    GetDic({ state, commit, dispatch }, prop) {
      if (!prop) return []
      let dic = state[prop]
      if (!dic) dic = state[prop] = { dicData: [] }
      if (dic.dicData) return dic.dicData
      return (dic.dicData = new Promise(async (resolve) => {
        dic.loading = true
        let dicData = []
        if (dic.url) {
          let res = await awaitResolve(getDic(dic))
          dicData = res?.detail || []
        } else if (dic.dictType) {
          dic.dicData = dispatch('GetDict', dic.dictType)
          dicData = await dic.dicData
        }
        commit('SET_DIC_DATA', { prop, dicData })
        resolve(dicData)
        dic.loading = false
        return dicData
      }))
    },
    // 获取所有字典
    GetAllDic({ state, dispatch }) {
      for (const stateKey in state) {
        requestLimitNum(6)
        requestQueue(() => dispatch('GetDic', stateKey))
      }
    },
    // 清除字典
    ClearDic({ commit }, prop) {
      commit('SET_DIC_DATA', {
        prop,
        dicData: null
      })
    },
    // 刷新字典
    async RefreshDic({ dispatch }, prop) {
      await dispatch('ClearDic', prop)
      return await dispatch('GetDic', prop)
    },
    // 当字典无值时，重新获取字典
    GetDicRetry({ state, dispatch }, prop) {
      let dic = state[prop]
      if (!dic) return []

      if (validatenull(dic.dicData) && !(dic.dicData instanceof Promise)) {
        dispatch('ClearDic', prop)
        return dispatch('GetDic', prop)
      }
      return dic.dicData
    },
    // 处理avueCrud/avueForm的option的column，为column子项添加dicData和props
    HandleOption({ dispatch, state }, { column = [], group, dic } = {}) {
      dic = validData(dic, {})
      if (column && group) {
        group.forEach((item) => {
          column = column.concat(item.column)
        })
      }
      if (!Array.isArray(column)) return []
      column.forEach((column) => {
        let dicName = column.dicType || column.prop
        if (hasDicType.includes(column.type)) {
          if (validatenull(column.dicData) || column.dicOrigin === 'dic') {
            !column.dicData && (column.dicData = []) // 为了使 column.dicData 变成响应式的：在赋值 this 之前设置一个空数组
            !dic[column.prop] && (dic[column.prop] = [])
            dispatch('GetDic', dicName).then((dicData) => {
              // console.log(dicName, dicData)
              if ((validatenull(column.dicData) || column.dicOrigin === 'dic') && dicData) {
                column.dicData = dicData
                column.dicOrigin = 'dic' // dicData 是通过 HandleOption 设置的

                dic[column.prop] = validData(dic[column.prop], dicData)
              }
            })
          } else {
            // dicData 是事先设置的，不是通过 HandleOption 设置的
            column.dicOrigin = 'self'
          }
        }
      })
      return dic
    }
  }
}

function dicToCommon(dic, props = {}) {
  if (!Array.isArray(dic) || dic.length === 0) return dic
  let { label = 'label', value = 'value', children = 'children' } = props
  if (label === 'label' && value === 'value' && children === 'children') return dic
  dic.forEach((dicItem) => {
    dicItem.label = dicItem[label]
    dicItem.value = dicItem[value]
    if (dicItem[children]) {
      let childrenList = dicToCommon(dicItem[children], props)
      dicItem.children = validatenull(childrenList) ? undefined : childrenList
    }
  })
  return dic
}

export default dic
