<template>
  <BaseDialog
    height="60vh"
    width="800px"
    :dialogVisible.sync="dialog"
    title="批量修改分类"
    v-bind="$attrs"
    v-on="$listeners">
    <div class="bulk-change-cate-formomponent">

      <BaseForm  ref="form" label-width="100px" :cols="formField" :form="form" >
        <template #categorySlot="{ scoped: { prop } }">
          <ImageTypeTreeSelect ref="imageTypeTreeSelect" :selectedValue.sync="form[prop]" :maxHeight="200" />
        </template>
      </BaseForm>

      <div class="table-wrapper">
        <CommonTable height="auto" :selection="false" :cols="bulkCols" :infoData="data">
          <template #mergeSlot="{ scoped }">
            <div class="merge-wrapper">
              <el-image fit="contain" :src="scoped.thumbnailPath"> </el-image>
              <div class="name">{{ scoped.title }}</div>
            </div>
          </template>
        </CommonTable>
      </div>
    </div>

    <template #footer>
      <LoadingBtn @click="cancel"> 取消 </LoadingBtn>
      <el-button @click="doSubmit" type="primary">确定</el-button>
    </template>
  </BaseDialog>
</template>
<script>

import { commonFromMixin } from '@/mixins'
import ImageTypeTreeSelect from "@/components/imageTypeTreeSelect"
import { bulkCols, formField } from './cols'

import { categoryBatchUpdate } from '@/api/imageApi'


export default {
  components: { ImageTypeTreeSelect },
  mixins: [ commonFromMixin ],
  props: {
    cData: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      bulkCols,
      data: this.cData,
      form: {
        categoryId: ''
      },
      loading: false,
      formField
    }
  },

  methods: {
    async doSubmit() {
      const valid = await this.$refs['form'].validate()
      if (!valid) return
      const { categoryId } = this.form
      this.loading = true
      try {
        const { code } = await categoryBatchUpdate({
          categoryId: categoryId,
          imageIdList: this.data.map(item => item.id),
          platformType: 1
        })
        if ($SUC({ code })) {
          this.success()
        }
      } catch (err) {
        console.log('err', err)
      }
      this.loading = false
    },

    success() {
      this.$message.success('操作成功')
      this.resetForm()
      this.sup_this && this.sup_this.init()
    }
  }
}
</script>
<style lang="scss" scoped>
.bulk-change-cate-formomponent {
  .merge-wrapper {
    display: flex;
    align-items: center;
    .el-image {
      flex: 0 0 44px;
      width: 44px;
      height: 44px;
      background: $bg-color;
    }
    .name {
      margin-left: 10px;
      flex: 1;
    }
  }
}
</style>
