import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

export function listData(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/externalOrder/orderList',
    method: 'post',
    data
  })
}
export function listUpdate(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/order/updateStatus',
    method: 'post',
    data
  })
}
export function updateStatusBatch(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/order/updateStatusBatch',
    method: 'post',
    data
  })
}

export function productData(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/externalOrderItem/orderItemList',
    method: 'post',
    data
  })
}

export function sendFactory(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/order/sendToFactory',
    method: 'post',
    data
  })
}

export function exportAll(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/order/exportAll',
    method: 'post',
    responseType: 'blob',
    data
  })
}

export function exportDeclarationInfo(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/order/exportBigGoodsDeclarationInfo',
    method: 'post',
    responseType: 'blob',
    data
  })
}

export function cutOrder(data) {
  return request({
    url: '/externaladmin/orderService/order/cutOrder',
    method: 'post',
    data
  })
}

// 下载面单
export function downloadWaybillFile(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/order/downloadWaybillFile',
    method: 'post',
    responseType: 'blob',
    data
  })
}

//获取获取批量运单号
export function getBatchWaybill(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/logisticsService/waybillApplyRecord/batchWaybill',
    method: 'post',
    data
  })
}
