<template>
  <BaseDialog minHeight="300px" :title="title" :dialogVisible.sync="dialog" width="500px">
    <template>
      <BaseForm ref="form" label-width="100px" :cols="formField" :form="form" :formVisible="dialog">
        <template #parentSlot="{ scoped: { prop, placeholder } }">
          <ImageTypeTreeSelect ref="imageTypeTreeSelect" type="category" :selectedValue.sync="form[prop]" :maxHeight="200" />
        </template>
      </BaseForm>
    </template>
    <template #footer>
      <el-button :loading="loading" type="primary" @click="doSubmit"> 确认 </el-button>
      <LoadingBtn @click="cancel"> 取消 </LoadingBtn>
    </template>
  </BaseDialog>
</template>
<script>
import { mapGetters } from "vuex"
import { padStart, padEnd, cloneDeep } from 'lodash'
import ImageTypeTreeSelect from '@/components/imageTypeTreeSelect'
import { editImageTypeFiled as formField } from '../../field'
import { commonFromMixin } from '@/mixins'
import { edit } from '@/api/imageTypeApi'

export default {
  mixins: [commonFromMixin],
  components: {
    ImageTypeTreeSelect
  },
  data() {
    return {
      loading: false,
      formField,
      form: {
        name: ''
      },
      treeCodeMap: {},
      picCates: {}
    }
  },
  
  computed: {
    ...mapGetters({
      data: 'category',
    })
  },

  methods: {
    async doSubmit() {
      const valid = await this.validate()
      if (!valid) return
      this.doEdit()
    },
    async doEdit() {
      this.loading = true
      try {
        let treeCode
        const { parentId, ...form } = this.form
        let data = cloneDeep(this.data)
        this.setRowLevel(data)
        // 根据选择的父ID去计算treeCode
        const pItem = cloneDeep(this.treeCodeMap[parentId])
        const cate = this.picCates[form.id]
        if (pItem){
          //父层级
          const p$level = this.picCates[parentId].$level
          // 当前分类所在层级
          const { id } =  cate;
          
          if(p$level == 1){
            pItem[0] = parentId
            pItem[1] = id
            pItem[2] = 0
            pItem[3] = 0
          }
          if(p$level == 2){
            pItem[1] = parentId
            pItem[2] = id
            pItem[3] = 0
          }
          if(p$level == 3){
            pItem[2] = parentId
            pItem[3] = id
          }
          if(p$level == 4){
          
          }
          treeCode = pItem.map(code => padStart(code, 6, '0')).join('')
        }else{
          // 父级为空的修改
          treeCode = padEnd(padStart(form.id , 6, '0'), 24, '0')
        }
        const { code } = await edit(Object.assign({}, form, { parentId: parentId ? parentId : null, treeCode }))
        if ($SUC({ code })) {
          this.success()
        }
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    setRowLevel(data, parentLevel = 0) {
      //初始化
      let curLevel = ++parentLevel
      data.forEach(row => {
        row.$level = curLevel
        this.picCates[row.id] = row
        // 第一级
        if (curLevel == 1){
          row.treeCode = [row.id, 0, 0, 0]
          this.treeCodeMap[row.id] = row.treeCode
        }
        // 第二级
        if (curLevel == 2){
          row.treeCode = [row.parentId, row.id, 0, 0]
          this.treeCodeMap[row.id] = row.treeCode
        }
        // 第三级
        if (curLevel == 3){
          let pCode = this.treeCodeMap[row.parentId]
          if (pCode){
            row.treeCode = pCode
            row.treeCode[2] = row.id
            this.treeCodeMap[row.id] = pCode
          }
          
        }
        // 第四级
        if (curLevel == 4){
          let pCode = this.treeCodeMap[row.parentId]
          if (pCode){
            row.treeCode = pCode
            row.treeCode[3] = row.id
          }
        }
        if (row.children) this.setRowLevel(row.children, curLevel)
      })
    }
  }
}
</script>