<template>
  <div class="line inline-block">
    <el-button size="mini" :loading="loading" type="primary" @click="to">
      批量修改分类
    </el-button>
    <Form
      ref="form"
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :sup_this="sup_this"
      :cData="data"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import Form from './form'
export default {
  components: { Form },

  props: {
    sup_this: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      dialogVisible: false,
      data: []
    }
  },

  methods: {
    async to() {
      try {
        const [err, data] = await this.sup_this.getAllSelectedDataData()
        if (!data.length) return
        this.data = cloneDeep(data)
        this.$nextTick(() => {
          this.dialogVisible = true
        })
      } catch (err) {}
    }
  }
}
</script>
