<template>
  <el-dialog
    v-el-drag-dialog
    :title="option.title"
    :visible.sync="dialogVisible"
    top="5vh"
    width="888px"
    custom-class="distribution-dialog dialog-header-border"
    append-to-body
  >
    <avue-crud
      ref="crud"
      :data="tableData"
      :option="option.tableOption"
      :page="tablePage"
      :tableLoading="tableLoading"
      @size-change="sizeChange"
      @current-change="pageChange"
      @search-change="searchChange"
    >
      <template #radio="{ row }">
        <el-checkbox :value="checked(row)" @change="changeHandler(row, $event)"></el-checkbox>
      </template>

      <template #checkbox="{ row }">
        <el-checkbox :value="checked(row)" @change="multipleSelectedHandler(row, $event)"></el-checkbox>
      </template>
    </avue-crud>
    <template #footer>
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="onsubmit">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { avueCrud, dialogComponentsMixin } from '@/mixins'
import { getOption } from '../const'
import { isArray, uniq } from 'lodash'

export default {
  mixins: [dialogComponentsMixin, avueCrud()],

  props: {
    type: {
      type: String,
      default: 'default'
    },

    resetMergeData: Object
  },

  data() {
    return {
      loading: false,
      tablePage: {
        pageIndex: 1,
        pageSize: 5,
        total: 0
      }
    }
  },

  computed: {
    option({ type }) {
      return getOption(type)
    },

    permisionList({ option }) {
      return option.permisionList || {}
    },

    getList({ option }) {
      return option.getListFn
    },

    value({ $attrs: { value } }) {
      return value
    },

    checked({ value }) {
      return (row) => {
        if (isArray(value)) {
          return !!value.find(item => item == row.id)
        }
        console.log('value', value)
        return row.id == value
      }
    },

    submitFuncApi({ option }) {
      return option.addSubmitFuncApi
    }
  },

  methods: {
    changeHandler({ id }, checked) {
      this.$emit('input', checked ? id : null)
    },

    multipleSelectedHandler(row, checked) {
      let value = this.value
      if (!value) value = []
      if (!isArray(value)) value = [value]
      const copyValue = [...value]
      if (checked) {
        copyValue.push(row.id)
      } else {
        const fIndex = copyValue.findIndex(item => item == row.id)
        if (fIndex == -1) return
        copyValue.splice(fIndex, 1)
      }
      this.$emit('input', uniq(copyValue))
    },

    async onConfirm() {
      return this.$confirm('是否分配该分组?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'primary'
      })
        .then(() => {
          return true
        })
        .catch(() => {
          return false
        })
    },

    async onsubmit() {
      const value = this.$attrs.value
      //校验表单
      if (!value) return this.$message.warning('请选择数据，再进行此操作！')
      if (!(await this.onConfirm())) return
      this.loading = true
      const [err] = await this.doFunc(value)
      this.loading = false
      if (err) return
      this.$message.success('操作成功')
      this.$emit('successHandler', value)
      this.dialogVisible = false
    },

    async doFunc(value) {
      try {
        const { code, detail } = await this.submitFuncApi.call(this, value)
        return [!$SUC({ code }), detail || []]
      } catch {
        return [true, []]
      }
    }
  }
}
</script>
