<template>
  <XlsxTable :isTrim="false" :clearQuotes="false" class="inline-block" get-first-merge-data @on-select-all-file="importProductPrice" @on-select-file-name="onSelectFileName">
    <el-button type="primary" class="text-small mb20" :loading="loading">导入产品价格</el-button>
  </XlsxTable>
</template>

<script>
import XlsxTable from '@/components/xlsxTable.vue'
import { sysKeys, spanCols, requiredCols } from './const'
import priceApi from '@/api/product/price'
import { changeObjKey } from '@/utils/utils/excelUtil'
import { validData } from '@/components/avue/utils/util'
import { mapGetters } from 'vuex'
import { uniq, map } from 'lodash'
import { accDiv, accMul } from '@/utils'

export default {
  name: 'importPriceBtn',
  components: {
    XlsxTable
  },
  data() {
    return {
      loading: false,
      errorMsg: []
    }
  },
  computed: {
    ...mapGetters(['memberLevelList']),
    lowestDiscount({ memberLevelList }) {
      return memberLevelList.reduce((prev, next) => {
        if (next.priceDiscount < prev) return next.priceDiscount
        return prev
      }, 1)
    }
  },
  methods: {
    async importProductPrice(excelData) {
      await this.$store.dispatch('GetDic', 'memberLevelList')
      const tempArr = this.formatPriceTableToSys(excelData)
      // return console.log(tempArr)
      if (this.errorMsg.length) {
        return this.$message.warning(this.errorMsg.join('；'))
      }

      this.loading = true
      const res = await awaitResolve(
        priceApi.importPrice({
          list: tempArr
        })
      )
      this.loading = false
      if (!res) return

      this.$message.success('操作成功')
      this.$emit('success')
      // console.log(res)
    },
    check(body, levelCategoryItemList) {
      console.log(body, levelCategoryItemList)
      const errorMsg = []
      const levelCategoryItemLabel = map(levelCategoryItemList, 'label')
      const requiredCol = requiredCols.concat(levelCategoryItemLabel)
      body.forEach(item => {
        requiredCol.forEach((label) => {
          if (!item[label]) {
            errorMsg.push(`${label}不能为空`)
          }
        })

        // let lowestPrice = accDiv(Math.ceil(accMul(accDiv(item[sysKeys.lowerLimitPrice], this.lowestDiscount), 100)), 100)
        // if (levelCategoryItemLabel.some((label) => item[label] && (Number(lowestPrice) > Number(item[label])))) {
        //   errorMsg.push(`${item[spanCols.name]}的尺码${item[sysKeys.sizeName]}的档位价格应不低于${lowestPrice}`)
        // }
      })
      this.errorMsg = uniq(errorMsg)
    },
    formatPriceTableToSys(excelData) {
      // console.log('importProductPrice', JSON.parse(JSON.stringify(excelData)))
      const { header, body } = this.formatPriceTable(excelData)
      console.log(header, body)
      const levelCategoryItemList = []
      header.forEach(item => {
        const tempArr = item.match(/(?<=：)\d+-\d+.?(?=件)/)
        console.log("tempArr",tempArr)
        if (tempArr?.length) {
          const [minCount, maxCount] = tempArr[0].split('-')
          levelCategoryItemList.push({
            label: item,
            minCount,
            maxCount
          })
        }
      })
      // console.log(levelCategoryItemList)
      const tempArr = []
      body.forEach(item => {
        let lastItem = tempArr[tempArr.length - 1] || {}
        if (item[spanCols.index] && item[spanCols.index] !== lastItem.index) {
          const sysItem = changeObjKey(
            item,
            spanCols
          )
          Object.assign(sysItem, {
            productCategory: {
              name: sysItem.productCategoryName
            },
            sizeList: [],
            levelCategory: {
              name: this.priceTableName,
              levelCategoryItemList
            }
          })
          tempArr.push(sysItem)

          lastItem = sysItem
        }
        lastItem.sizeList.push({
          sizeName: item[sysKeys.sizeName],
          lowerLimitPrice: item[sysKeys.lowerLimitPrice],
          sizeLevelCategoryPriceList: levelCategoryItemList.map(({ label }) => ({ price: item[label] }))
        })
      })

      this.check(body, levelCategoryItemList)

      return tempArr
    },
    formatPriceTable({ header, body }) {
      // console.log(header, body, merges)
      // __EMPTY
      let realHeader
      let newHeader
      if (header.some(title => title === '__EMPTY')) {
        realHeader = body.shift()
        newHeader = header.map(item => realHeader[item])
      } else {
        realHeader = newHeader = header
      }
      let curItem = {}
      return {
        header: newHeader,
        body: body.map(item => {
          let tempObj = {}
          header.forEach((j, index) => {
            tempObj[newHeader[index]] = item[j]
          })
          // 合并单元格为空时，取第一行数据
          if (tempObj[spanCols.index] && tempObj[spanCols.index] !== curItem[spanCols.index]) {
            curItem = tempObj
          } else {
            for (let key in spanCols) {
              key = spanCols[key]
              tempObj[key] = validData(tempObj[key], curItem[key])
            }
          }
          return tempObj
        })
      }
    },

    onSelectFileName(sheetNames) {
      this.priceTableName = sheetNames[0]
    }
  }
}
</script>