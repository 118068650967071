<template>
  <!-- 图片类型 -->
  <section
    class="app-container app-flex-col-container protoCategoryPage"
    v-p="['externaladmin:picture:category:imageCategoryList']"
  >
    <EHeader :query="query" :sup_this="sup_this" v-p="['externaladmin:picture:category:addCategory']" />
    <PaginationBar
      :page="page"
      :size="size"
      :total="total"
      @refreshTableEventFun="refreshTableEventFun"
    />
    <div class="flex-col-content tableBox">
      <el-table
        v-loading="tableLoading"
        :data="data"
        style="width: 100%; border: none"
        height="100%"
        row-key="id"
        border
        default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="name" label="分类"> </el-table-column>
        <el-table-column align="center" label="操作" width="380">
          <template slot-scope="{ row, $index }">
            <ColorTextBtn type="warning" v-if="$index == 0 && page == 1">
              系统预设分类，不可编辑和删除
            </ColorTextBtn>
            <template v-else>
              <AddChildCate
                :sup_this="sup_this"
                :data="row"
                v-p="['externaladmin:picture:category:addChildrenCategory']"
                v-if="row.$level < 4"
              ></AddChildCate>
              <EditCate :sup_this="sup_this" :data="row" v-p="['externaladmin:pictre:category:editCategory']" />
              <DelCate :sup_this="sup_this" :data="row" v-p="['externaladmin:picture:category:deleteCategory']" />
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <PaginationBar
      :page="page"
      :size="size"
      :total="total"
      @refreshTableEventFun="refreshTableEventFun"
    />
  </section>
</template>

<script>
import EHeader from './module/header'
import { initDataMixin } from '@/mixins'
import AddChildCate from './module/addChildCate'
import EditCate from './module/editCate'
import DelCate from './module/delCate'

export default {
  mixins: [initDataMixin],
  components: {
    EHeader,
    AddChildCate,
    EditCate,
    DelCate
  },
  data() {
    return {
      url: '/externaladmin/productService/imageCategory/list',
      sup_this: this,
      treeCodeMap: {}
    }
  },
  methods: {
    initCallBack() {
      this.setRowLevel(this.data)
    },
    setRowLevel(data, parentLevel = 0) {
      let curLevel = ++parentLevel
      data.forEach(row => {
        row.$level = curLevel
        // 第一级
        if (curLevel == 1){
          row.treeCode = [row.id, 0, 0, 0]
          this.treeCodeMap[row.id] = row.treeCode
        }
        // 第二级
        if (curLevel == 2){
          row.treeCode = [row.parentId, row.id, 0, 0]
          this.treeCodeMap[row.id] = row.treeCode
        }
        // 第三级
        if (curLevel == 3){
          let pCode =  this.treeCodeMap[row.parentId]
          if (pCode){
            row.treeCode = pCode
            row.treeCode[2] = row.id
          }
        }
        // 第四级
        if (curLevel == 4){
          let pCode =  this.treeCodeMap[row.parentId]
          if (pCode){
            row.treeCode = pCode
            row.treeCode[3] = row.id
          }
        }
        if (row.children) this.setRowLevel(row.children, curLevel)
      })
      
      
    }
  }
}
</script>
<style lang="scss" lang="scss">
.protoCategoryPage {
  .el-table::before,
  .el-table--border::after {
    background: transparent !important;
  }
}
.tableBox {
  padding-bottom: 30px;
}
</style>
