<template>
  <div v-loading="tableLoading" class="app-container flex-one-page">
    <div class="wrapper">
      <div v-scrollTop>
        <orderState
          v-if="!isDeleted"
          ref="orderState"
          type="temu"
          :query="query"
          :sup_this="sup_this"
          @updateCheck="updateCheck"
          @takeStatus="takeStatus"
        ></orderState>
        <temuSearch
          ref="form"
          :query="query"
          :orderStatus="orderStatus"
          :sup_this="sup_this"
          :postData="postData"
          :inDispatch="false"
          :isDeleted="isDeleted"
        ></temuSearch>
        <div class="flex-middle">
          <template v-if="orderStatus === '' || isDeleted">
            <el-dropdown trigger="click" @command="orderExport" v-if="finalPermission.export">
              <el-button type="primary" size="small">
                订单导出<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="select">导出勾选订单</el-dropdown-item>
                <el-dropdown-item command="all">导出全部订单</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <changeStateBtn v-if="finalPermission.batchUpdate"  isTemuOrder :data="factoryData" :sup_this="sup_this"></changeStateBtn>
        </div>
        <temuTable
          ref="table"
          class="flex-one"
          :sup_this="sup_this"
          :orderListData="data"
          :orderStatus="orderStatus"
          :permission="finalPermission"
          :isWaybillCreate="true"
          @takeFactory="takeFactory"
        ></temuTable>
      </div>
      <div class="hidden_block"></div>
      <div class="pagination_wrapper">
        <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
      </div>
    </div>
  </div>
</template>

<script>
import orderState from './module/orderListModule/orderState'
import temuSearch from './module/orderListModule/temuSearch'
import temuTable from './module/orderListModule/temuTable'
import pagination from './module/orderListModule/pagination'
import changeStateBtn from '@/views/order/module/orderListModule/changeStateBtn'
import { initDataMixin } from '@/mixins'
import {checkPermission, exportWrap} from '@/utils'
import { DELETED, ORDER_IS_DELETED_YES } from '@/utils/constant'
import handleSearchFormProps from '@/views/order/utils/handleSearchFormProps'
import temuOrderListApi from '@/api/order/temuOrderList'

let deletedOption = {
  label: '已删除',
  value: DELETED,
  searchValue: {
    orderStatus: DELETED
  },
  permission: checkPermission('externaladmin:orderManage:deleted'),
  childPermission: {
    export: checkPermission('externaladmin:orderManage:deleted:export'),
    search: checkPermission('externaladmin:orderManage:deleted:search')
  }
}

export default {
  name: 'temuOrderList',
  mixins: [initDataMixin],
  components: {
    orderState,
    temuSearch,
    temuTable,
    changeStateBtn,
    pagination
  },
  data() {
    const merge = this.$route.path.split('/').pop() !== 'deleted' ? { } : {}
    return {
      isTemuOrder: true,
      sup_this: this,
      method: 'post',
      initBool: false,
      orderStatus: '0',
      factoryData: [],
      permission: {},

      mergeData: Object.assign(
        {
          orderItems: [
            {
              asc: false,
              column: 'order_time'
            },
            {
              asc: false,
              column: 'id'
            }
          ]
        },
        merge
      ),
      postData: {}
    }
  },
  computed: {
    isDeleted() {
      return this.$route.path.split('/').pop() === 'deleted'
    },

    url({ isDeleted }) {
      return '/externaladmin/temuOrderService/temuOrder/temuOrderList'
    },
    handleSearchFormProps() {
      return {
        ...handleSearchFormProps,
        orderStatus: (val) => {
          if (val == DELETED) {
            return {
              $isMergeData: true,
              isDeleted: ORDER_IS_DELETED_YES
            }
          }
          return val
        }
      }
    },
    finalPermission({ permission, isDeleted }) {
      if (isDeleted) {
        return deletedOption.childPermission
      }
      return permission
    }
  },
  watch: {
    isDeleted: {
      handler(n) {
        if (n) {
          Object.assign(this.query, deletedOption.searchValue)
          this.init()
        }
      },
      immediate: true
    }
  },

  mounted() {
    this.permission = (this.isDeleted ? deletedOption : this.$refs.orderState).childPermission || {}
    if (!this.permission) {
      this.$message.error('页面无权限！')
    }
  },
  methods: {
    beforeInitData() {
      this.postData = this.params
    },
    initCallBack(res) {
      // console.log('ressssssss', res)
      let tempArr = []
      this.data.forEach((item) => {
        tempArr.push({
          isHeader: true,
          orderCode: item.orderCode,
          userName: item.userName,
          orderInfo: item
        })
        item.$sortTime = this.getSortTime(item)
        item.$lastTime = item.$sortTime[item.$sortTime.length - 1]
        tempArr.push(item)
      })
      this.data = tempArr
    },
    getSortTime(item) {
      let times = [
        {
          label: '导入时间',
          value: item.orderTime,
          prop: 'orderTime'
        },
        {
          label: '面单下载时间',
          value: item.expressWaybillDownloadTime,
          prop: 'expressWaybillDownloadTime'
        },
        {
          label: '取消时间',
          value: item.cancelTime,
          prop: 'cancelTime'
        },
        {
          label: '截单时间',
          value: item.cutoffTime,
          prop: 'cutoffTime'
        },
        {
          label: '排单时间',
          value: item.sendTime,
          prop: 'sendTime'
        },
        {
          label: '发货时间',
          value: item.deliveryTime,
          prop: 'deliveryTime'
        },
        {
          label: '退货申请时间',
          value: item.returnApplyTime,
          prop: 'returnApplyTime'
        },
        {
          label: '退货完成时间',
          value: item.returnCompleteTime,
          prop: 'returnCompleteTime'
        },
        {
          label: '备货单创建时间',
          value: item.purchaseOrderCreateTime,
          prop: 'purchaseOrderCreateTime'
        }
      ]
      if (item.productionTime) {
        times.push({
          label: '生产时间',
          value: item.productionTime,
          prop: 'productionTime'
        })
      }
      return times
        .filter((time) => time.value)
        .sort((a, b) => new Date(a.value).getTime() - new Date(b.value).getTime())
    },
    takeStatus(data) {
      this.orderStatus = data
    },
    takeFactory(data) {
      this.factoryData = data
      // console.log(this.factoryData)
      console.log(this.factoryData)
    },
    async orderExport(type) {
      if (type === 'select' && this.factoryData.length === 0) return this.$message.warning('请先选择订单')
      //return await orderExport(type === 'select' ? this.factoryData : [], this, this.params)
      
      const postData = type === 'select'
        ? {
          idList: this.factoryData.map((item) => item.id || item?.orderInfo?.id)
        }: {
          ...this.params
        }
      
      return exportWrap(
        temuOrderListApi.export(postData),
        '导出订单'
      )
      
    },
    updateCheck(newCheck) {
      this.permission = newCheck
    },
    changeLogistics() {
      const newArr = this.factoryData.map(({ id }) => {
        return id
      })
      return newArr
      // console.log('newArr', newArr)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  overflow: hidden;
  padding: 20px 0 95px 15px;
  position: relative;
  .wrapper {
    height: 100%;
    overflow: auto;
    border-bottom: 1px solid #ebeef5;
    padding-right: 15px;
  }
  .pagination_wrapper {
    position: fixed;
    bottom: 32px;
    right: 45px;
    // background-color: rgb(25, 224, 158);
    z-index: 10;
  }
  .hidden_block {
    position: absolute;
    bottom: 95px;
    right: 0;
    width: 23px;
    height: 1px;
    background-color: #fff;
  }
  ::v-deep {
    .el-table {
      overflow: hidden;
    }
    .list-state-group .el-radio-button__inner {
      width: 135px;
    }
  }
}
</style>
