import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

export function list(params) {
  return request({
    url: '/api/image/image/',
    method: 'get',
    params
  })
}

export function ownerList(params) {
  return request({
    url: '/api/image/owner_image/',
    method: 'get',
    params
  })
}

export function add(data, config) {
  return request({
    url: '/api/image/image/',
    method: 'post',
    data,
    ...config
  })
}

export function publicAdd(data, config, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/image/create',
    method: 'post',
    data,
    ...config
  })
}

export function bulkAddCategory(data, config) {
  return request({
    url: '/api/image/image/bulk_add_category/',
    method: 'post',
    data,
    ...config
  })
}

export function read(data) {
  return request({
    url: `/api/image/image/${data}/`,
    method: 'get',
    data
  })
}

export function edit(id, data) {
  return request({
    url: '/api/image/image/' + id + '/',
    method: 'put',
    data
  })
}

export function update(id, data) {
  return request({
    url: '/api/image/image/' + id + '/',
    method: 'patch',
    data
  })
}

export function publicUpdate(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/image/update',
    method: 'post',
    data
  })
}

export function categoryBatchUpdate(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/image/categoryBatchUpdate',
    method: 'post',
    data
  })
}

export function changePublicUpdate(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/image/update',
    method: 'post',
    data
  })
}

// 禁用图片
export function disablePublicPicture(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/image/batchDisable',
    method: 'post',
    data
  })
}

export function updateImage(id, data) {
  return request({
    url: `/api/image/image/${id}/change_images/`,
    method: 'patch',
    data
  })
}

export function del(id) {
  return request({
    url: '/api/image/image/' + id + '/',
    method: 'delete'
  })
}

export function bulkDel(data) {
  return request({
    url: '/api/image/image/bulk_delete/',
    method: 'delete',
    data
  })
}

export function bulkShare(data) {
  return request({
    url: '/api/image/image/bulk_share/',
    method: 'post',
    data
  })
}

export function bulkCreate(data) {
  return request({
    url: '/api/image/image/bulk_create/',
    method: 'post',
    data
  })
}

export function check(data) {
  return request({
    url: '/api/image/image/check_img/',
    method: 'post',
    data
  })
}

export function publicCheck(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/image/checkOpenImage',
    method: 'post',
    data
  })
}

//禁用
export function disable(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/image/batchDisable',
    method: 'post',
    data
  })
}

export function publicDisable(data) {
  return request({
    url: '/api/image/public_image/disable/',
    method: 'post',
    data
  })
}

//解除禁用
export function cancelDisable(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/image/batchCancelDisable',
    method: 'post',
    data
  })
}
// 解除禁用
export function publicCancelDisable(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/image/batchCancelDisable',
    method: 'post',
    data
  })
}
// 图片分类列表
export function categoryName(data) {
  return request({
    url: '/externaladmin/productService/imageCategory/list',
    method: 'post',
    data
  })
}


//公共图库列表
export function getPublicList(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/image/list',
    method: 'post',
    data
  })
}
