<template>
  <el-dialog
    append-to-body
    :visible.sync="dialogVisible"
    uiid="zd-audit-dialog"
    title="审核"
    width="972px"
    top="8vh"
    class="dialog-warpper"
    @closed="dialogClose"
  >
    <div class="form-tabs-table table-header-border form-item-mb0">
      <avue-form ref="form" v-model="form" :option="formOption">
        <template #compensateType> {{ findByvalue(compensateType, form.compensateType) }} </template>

        <template #deliveryAddress>
          {{ form.contactAddress }}
          <span class="ml50">{{ form.contactName }} {{ form.contactMobile }}</span>
        </template>
        <template #receiptFilePathList>
          <defaultImg
            style="height: 40px"
            disabled
            :preview-src-list="form.receiptFilePathList || []"
            :carouselList="form.receiptFilePathList || []"
          ></defaultImg>
        </template>
      </avue-form>
      <el-tabs v-model="activeName">
        <el-tab-pane v-for="item in tabList" :key="item.value" :label="item.label" :name="item.value">
          <div v-if="item.value == 'approveInfo'" style="padding-top: 6px">
            <el-timeline :reverse="reverse">
              <el-timeline-item v-for="(activity, index) in approveInfo" :key="index" :timestamp="activity.timestamp">
                {{ activity.content }}
              </el-timeline-item>
            </el-timeline>
          </div>
          <customTable
            v-else-if="data[item.value]"
            v-for="(tableAttr, index) in item.tableAttrs"
            :key="index"
            :data="data[item.value][index]"
            v-bind="tableAttr"
          >
            <template #menuLeft>
              {{ tableAttr.header }}
            </template>
            <template #productInfoText="{ row }">
              <div class="ml20 nowrap">
                <div>{{ row.productCustomSkuCode || '暂无' }} x {{ row.productCount }}</div>
                <div>{{ row.productCnName }}</div>
                <div>款式：{{ row.productStructName }}</div>
                <div>颜色：{{ row.colorName || '暂无' }}</div>
                <div>尺码：{{ row.productSizeName }}</div>
                <div>单价：{{ row.$internalUnitPrice }}</div>
              </div>
            </template>
            <template #salesAmount="{ row }">
              <totalLoans :data="row.orderInfo"></totalLoans>
            </template>
            <template #consigneeInfo="{ row }">
              <div v-if="!isFBAOrder">
                <div>{{ row.consigneeName }}</div>
                <div>【{{ row.countryCnName }}】</div>
              </div>
              <div v-else>
                <div>{{ initWaybillApplyRecord(row).consigneeName }}</div>
                <div>【{{ initWaybillApplyRecord(row).consigneeCountryCode }}】</div>
              </div>
            </template>
            <template #expressInfo="{ row }">
              <template v-if="!isFBAOrder">
                <div>{{ row.expressCompanyName }}</div>
                <div>
                  <span>{{ row.cnName || row.shippingMethodName }}</span>
                </div>
                <div v-if="row.expressWaybillCode">
                  「<span class="text-primary">{{ row.expressWaybillCode }}</span
                  >」
                </div>
                <span style="color: red">{{ expressExpireTimeDetail(row.expressExpireTime) }}</span>
              </template>
              <div v-else>
                <div>
                  <span>{{ initWaybillApplyRecord(row).expressShippingMethodCnName }}</span>
                </div>
                <div v-if="initWaybillApplyRecord(row).expressWaybillCode">
                  「<span class="text-primary">{{ initWaybillApplyRecord(row).expressWaybillCode }}</span
                  >」
                </div>
              </div>
            </template>
          </customTable>
        </el-tab-pane>
      </el-tabs>
    </div>
    <template #footer>
      <comfirmDialog
        v-if="showBtn"
        :title="confirmMsg[HANDLE_STATUS_REFUSE_BY_CUSTOMER]"
        :initValue="{
          amount: form.amount,
          compensateType: form.compensateType,
          responseType: form.responseType,
          detail,
          curType,
          type,
          handleStatus: statusDict[0]
        }"
        :openValidate="validate"
        uiid="zd-audit-dialog-reject"
        @submit="submit"
      />

      <comfirmDialog
        v-if="showBtn && type != 1"
        title="退回"
        :initValue="{
          paymentType: form.paymentType,
          responseType: form.responseType,
          amount: form.amount,
          compensateType: form.compensateType,
          detail,
          curType,
          type,
          handleStatus: statusDict[1]
        }"
        :openValidate="validate"
        uiid="zd-audit-dialog-return"
        @submit="submit"
      />

      <comfirmDialog
        v-if="showBtn"
        btnType="primary"
        :title="confirmMsg[HANDLE_STATUS_SUCCESS_BY_CUSTOMER]"
        :initValue="{
          paymentType: form.paymentType,
          responseType: form.responseType,
          amount: form.amount,
          compensateType: form.compensateType,
          detail,
          curType,
          type,
          handleStatus: statusDict[2]
        }"
        :openValidate="validate"
        uiid="zd-audit-dialog-confirm"
        @submit="submit"
      />

      <!-- <loadingBtn class="text-small" @click="handleClick(HANDLE_STATUS_REFUSED)">拒绝</loadingBtn>
      <loadingBtn class="text-small" type="primary" @click="handleClick(HANDLE_STATUS_PROCESSED)">通过</loadingBtn> -->
    </template>
  </el-dialog>
</template>
<script>
import { dialogFormMixin } from '@/mixins'
import defaultImg from '@/views/components/defaultImg'
import customTable from '@/views/components/customTable'
import totalLoans from '@/views/financial/afterSaleProcessingOfOrders/module/totalLoans'
import { getLabel } from '@/components/avue/utils/util'
import notProduced from './mixins/notProduced'
import produced from './mixins/produced'
import shipped from './mixins/shipped'
import { tabs } from './const'
import { afterShippedToExamine, afterShippedInfo } from '@/api/financial/afterSaleProcessingOfOrders'
import {
  HANDLE_STATUS,
  WAYBILL_TYPE_SYS,
  WAYBILL_TYPE_HAND,
  HANDLE_STATUS_PENDING,
  HANDLE_STATUS_SUCCESS_BY_CUSTOMER,
  HANDLE_STATUS_REFUSE_BY_CUSTOMER,
  HANDLE_STATUS_SUCCESS_BY_CUSTOMER_MANAGER,
  HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER_BACK,
  HANDLE_STATUS_SUCCESS_BY_FINANCE,
  HANDLE_STATUS_REFUSE_BY_FINANCE_BACK,
  HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER,
  HANDLE_STATUS_REFUSE_BY_FINANCE,
  IS_FIRST_WAYBILL_NO,
  ORDER_TYPE_FBA
} from '@/utils/constant'
import { mapGetters } from 'vuex'
import comfirmDialog from '../comfirmDialog.vue'
import { parsePrice } from '@/utils'
import { accAdd, accSub } from '@/utils'
import { findByvalue } from '@/components/avue/utils/util'
import { expressExpireTimeDetail } from '@/views/order/utils'

export default {
  components: {
    defaultImg,
    customTable,
    comfirmDialog,
    totalLoans
  },
  mixins: [dialogFormMixin, notProduced, produced, shipped],
  props: {
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      orderType: 0,
      detail: {
        realTemplateFreight: 0,
        maxAmount: 0,
        realAmount: 0,
        responseType: 0
      },
      showBtn: true,
      reverse: false,
      row: null,
      approveInfo: [],
      confirmMsg: {
        [HANDLE_STATUS_SUCCESS_BY_CUSTOMER]: '通过',
        [HANDLE_STATUS_REFUSE_BY_CUSTOMER]: '拒绝'
      },
      handleStatusDict: {
        // 对应三个动作，拒绝，退回，通过
        1: [HANDLE_STATUS_REFUSE_BY_CUSTOMER, null, HANDLE_STATUS_SUCCESS_BY_CUSTOMER], // 客服
        2: [
          HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER,
          HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER_BACK,
          HANDLE_STATUS_SUCCESS_BY_CUSTOMER_MANAGER
        ], // 客服经理
        3: [HANDLE_STATUS_REFUSE_BY_FINANCE, HANDLE_STATUS_REFUSE_BY_FINANCE_BACK, HANDLE_STATUS_SUCCESS_BY_FINANCE] // 财务
      },
      WAYBILL_TYPE_SYS,
      WAYBILL_TYPE_HAND,
      HANDLE_STATUS,
      HANDLE_STATUS_PENDING,
      HANDLE_STATUS_SUCCESS_BY_CUSTOMER,
      HANDLE_STATUS_REFUSE_BY_CUSTOMER,
      HANDLE_STATUS_SUCCESS_BY_CUSTOMER_MANAGER,
      HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER_BACK,
      HANDLE_STATUS_SUCCESS_BY_FINANCE,
      HANDLE_STATUS_REFUSE_BY_FINANCE_BACK,
      HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER,
      HANDLE_STATUS_REFUSE_BY_FINANCE,
      curType: 'notProduced',
      data: {},
      activeName: 'orderInfo'
    }
  },
  computed: {
    ...mapGetters(['refundType', 'compensateType']),

    isFBAOrder({ orderType }) {
      return orderType === ORDER_TYPE_FBA
    },

    initWaybillApplyRecord() {
      return (data) => {
        return $GET(data, 'initWaybillApplyRecord', {})
      }
    },

    amountColumn() {
      return this.formOption?.column?.find((item) => item.label === '退款金额') || {}
    },
    compensateTypeColumn() {
      return this.formOption?.column?.find((item) => item.label === '赔付类型') || {}
    },
    statusDict() {
      return this.handleStatusDict[this.type]
    },
    tabList() {
      if (this.curType === 'notProduced') {
        return this.deepClone(tabs).filter((item) => item.value !== 'refundInfo')
      }

      return tabs
    }
  },
  watch: {
    dialogVisible(n) {
      if (n) {
        this.activeName = tabs[0].value
      }
    }
  },
  methods: {
    findByvalue,
    expressExpireTimeDetail,
    submit(val, approveStatus) {
      // 如果是拒绝
      if (
        [
          HANDLE_STATUS_REFUSE_BY_CUSTOMER,
          HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER,
          HANDLE_STATUS_REFUSE_BY_FINANCE
        ].includes(approveStatus)
      ) {
        this.$set(this.form, 'handleStatus', 2)
      }
      this.form.approveStatus = approveStatus
      if (approveStatus != HANDLE_STATUS_SUCCESS_BY_FINANCE) {
        this.$set(this.form, 'refundRemark', val)
      } else {
        // 财务审核传密码参数
        this.$set(this.form, 'refundRemark', '')
        this.$set(this.form, 'approveConfirmPassword', val)
      }
      this.onsubmit()
    },
    async dialogOpen(row, type, showBtn) {
      this.showBtn = showBtn == 'final' || showBtn ? true : false
      this.row = row
      let res
      if (showBtn) {
        res = await awaitResolve(afterShippedToExamine({ id: row.id }))
        this.$nextTick(() => {
          this.compensateTypeColumn.formslot = false
        })
      } else {
        res = await awaitResolve(afterShippedInfo({ id: row.id }))
        this.$nextTick(() => {
          this.compensateTypeColumn.formslot = true
        })
      }

      if (!res) return
      let { detail } = res
      // 表明是已支付订单
      detail.$isPaidOrder = true
      detail.id = row.id
      detail.businessContactMobile = row.businessContactMobile

      detail.isPayOrder = detail.isPayOrder !== 0
      !detail.isPayOrder && (detail.paymentType = '免支付')
      detail.amount = (detail.isPayOrder && detail.amount) || 0

      detail.$handleType = getLabel('handleType', detail.handleType)
      // 退款方式
      detail.refundType = detail.refundType || this.refundType[0]?.value

      // 订单类型
      detail.orderType = row.orderType
      this.orderType = row.orderType

      // 运费
      const freight = detail.templateFreight

      if (type === 'shipped') {
        let maxAmount = detail.maxAmount
        // 运费
        // maxAmount=0 为免支付
        detail.$compensateAmount = {
          internalTotalPrice: maxAmount == 0 ? 0 : this.$math.subtract(maxAmount, freight),
          $freight: maxAmount == 0 ? 0 : freight || 0,
          $totalPrice: maxAmount,
          amount: detail.amount,
          compensateType2: detail.compensateType
        }
        //console.log(88888, this.compensateType[0]?.value)
        detail.compensateType = detail.compensateType || 3
      }
      this.curType = type
      this.setFormCopy(detail)
      this.detail = {
        realTemplateFreight: detail.maxAmount == 0 ? 0 : freight || 0,
        maxAmount: detail.maxAmount,
        realAmount: detail.realAmount,
        responseType: detail.responseType
      }
      // amount、refundType、compensateType 必传
      let delArr = ['amount', 'refundType', 'compensateType']
      let { oForm } = this
      delArr.forEach((key) => delete oForm[key])

      this.$set(this.formOption, 'readonly', showBtn == 'final' ? true : !showBtn)
      // 免支付订单 退款金额为0且不可修改
      this.amountColumn.type = detail.isPayOrder ? 'clickInput' : 'text'

      let orderItemLen = detail.orderItemList?.length || 0
      let data = detail.orderItemList?.map((item) => {
        item = Object.assign({}, detail, item, { $orderItemLen: orderItemLen })
        item.orderInfo = detail
        item.$internalUnitPrice = parsePrice(item.internalUnitPrice)
        return item
      })
      let orderItemList = detail.orderItemList?.filter((item) => item.isAfterSale) || []
      let refundInfo = orderItemList.map((item) => {
        item = this.deepClone(item)
        item.productCount = item.afterSaleProductCount
        item.$internalUnitPrice = parsePrice(item.realLowUnitPrice)
        return item
      })
      this.data = {
        orderInfo: [data],
        refundInfo: [refundInfo],
        payInfo: this.getTabPayInfo(detail)
      }
      let approveInfo = Array.isArray(data) && data.length ? data[0].approveRecordList : []

      this.approveInfo = []
      if (approveInfo && approveInfo.length) {
        this.approveInfo = approveInfo.map((item) => {
          let approveOpinion = item.approveOpinion ?? ''
          let approveUserName = item.approveUserName ?? ''
          let approveStatus = HANDLE_STATUS[item.approveStatusNew]
          return {
            content: `【${approveStatus}】${approveOpinion}(审核人：${approveUserName})`,
            timestamp: item.approveTime
          }
        })
      }

      this.dialogVisible = true
    },

    getTabPayInfo(detail) {
      let cDetail = this.deepClone(detail)
      // 应付金额/实付金额
      cDetail.$payAmount = this.getPayAmount(detail)

      let tempArr = []
      // changeRecordDTOList 为重新申请记录列表（升序排序），第一条为最开始的运单记录
      let changeRecordDTOList = cDetail.changeRecordDTOList || []
      if (changeRecordDTOList?.length > 1) {
        // let paymentType = !detail.isPayOrder ? '免支付' : '账户余额'
        changeRecordDTOList = changeRecordDTOList.map((item) => item.orderDTOInfoNew)
        // 只显示重新申请记录
        changeRecordDTOList.slice(1).forEach((item, index) => {
          let oItem = changeRecordDTOList[index]
          item.oExpressCompanyName = oItem.expressCompanyName
          item.oRealTemplateFreight = oItem.realTemplateFreight
          item.paymentType = cDetail.paymentType
          tempArr.push(item)
        })
      }

      return [[cDetail], tempArr]
    },
    getPayAmount(row) {
      let freight = row.templateFreight
      // if (row.waybillType !== WAYBILL_TYPE_SYS && row.orderType !== 2) {
      //   freight = 0
      // }
      if (!row.paymentFreightStatus && row.orderType === ORDER_TYPE_FBA && row.paymentType !== '免支付') {
        freight = 0
      }
      let tempObj = {
        freight,
        internalTotalPrice: row.internalTotalPrice,
        // 应付金额
        totalPrice: accAdd(row.internalTotalPrice, freight),
        // 实付金额
        realAmount: accSub(accAdd(row.internalTotalPrice, freight), row.couponAmount)
      }
      return {
        ...tempObj,
        $couponAmount: parsePrice(row.couponAmount),
        $freight: parsePrice(tempObj.freight),
        $totalPrice: parsePrice(tempObj.totalPrice),
        $realAmount: parsePrice(tempObj.realAmount),
        $internalTotalPrice: parsePrice(tempObj.internalTotalPrice)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .form-tabs-table {
    .form-container {
      margin-bottom: 10px;
    }
    .el-form-item__label {
      color: #969799;
    }
    .el-textarea {
      margin-top: 10px;
    }

    .crud-container {
      .el-table {
        margin-bottom: 0;
      }
    }
  }
  .el-tab-pane {
    max-height: 388px;
    overflow: auto;
  }
}
</style>
