import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

// 主账号分配原型-分销商
export function userDistributorPrototypeSet(data) {
  return request({
    url: '/externaladmin/authService/user/userDistributorPrototypeSet',
    method: 'post',
    data
  })
}

// 主账号批量取消分配原型-分销商
export function userDistributorPrototypeBulkDelete(data) {
  return request({
    url: '/externaladmin/authService/user/userDistributorPrototypeBulkDelete',
    method: 'post',
    data
  })
}

// 主账号分配原型-原型
export function distributorPrototypeDataSet(data) {
  return request({
    url: '/externaladmin/authService/user/distributorPrototypeDataSet',
    method: 'post',
    data
  })
}

// 主账号分配公共原图-分销商
export function userDistributorImageDataSet(data) {
  return request({
    url: '/externaladmin/authService/user/userDistributorImageDataSet',
    method: 'post',
    data
  })
}

// 主账号批量取消分配原图-分销商
export function userDistributorImageBulkDelete(data) {
  return request({
    url: '/externaladmin/authService/user/userDistributorImageBulkDelete',
    method: 'post',
    data
  })
}

// 主账号分配公共原图-原图
export function distributorImageDataSet(data) {
  return request({
    url: '/externaladmin/authService/user/distributorImageDataSet',
    method: 'post',
    data
  })
}

// 分销商获取分配组
export function getGroupInfo(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/groupInfo',
    method: 'post',
    data
  })
}

// 分销商获取公共图片分组
export function getImageGroupInfo(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/imageGroupInfo',
    method: 'post',
    data
  })
}

