<template>
  <TreeSelect
    :selectedValue.sync="value"
    :options="canChooseData"
    :defaultExpandLevel="2"
    :normalKeys="{
      id: 'id',
      name: 'name',
    }"
    placeholder="请选择分类"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import TreeSelect from "@/components/treeSelect";
import { mapGetters } from "vuex";
import { cloneDeep } from 'lodash'

export default {
  components: {
    TreeSelect,
  },
  props: {
    selectedValue: [Array, Number, String, Object],
    type: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      value: "",
    };
  },
  watch: {
    value(newVal) {
      this.$emit("update:selectedValue", newVal);
    },
    selectedValue: {
      handler(newVal) {
        this.value = newVal;
      },
      immediate: true
    }
  },
   computed: {
    ...mapGetters({
      data: 'category',
    }),
     
     
     canChooseData({ data, type }){
      if (type === 'default') return data
        // 第4级不能选
      const copyData = cloneDeep(data)
      this.setRowLevel(copyData)
       return copyData
     }
  },
  
  methods:{
    setRowLevel(data, parentLevel = 0) {
      let curLevel = ++parentLevel
      data.forEach(row => {
        row.$level = curLevel
        if (row.$level >= 3){
          row.children = []
        }
        if (row.children) this.setRowLevel(row.children, curLevel)
      })
    }
  }
};
</script>

<style>
</style>